




































import Vue from 'vue'

export default Vue.extend({
  name: 'AttachmentDialog',
  /* eslint-disable vue/require-prop-types */
  props: ['show', 'channel', 'close', 'lead'],
  computed: {
    hasMailroom(): boolean {
      return this.$auth.user?.roles?.includes('mailroom')
    },
  },
})
