var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VSnackbar',{attrs:{"color":"green","timeout":"4000","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.newTaskNotification = false}}},'VBtn',attrs,false),[_vm._v("\n        Close\n      ")])]}}]),model:{value:(_vm.newTaskNotification),callback:function ($$v) {_vm.newTaskNotification=$$v},expression:"newTaskNotification"}},[_vm._v("\n    A new task has been assigned to you.\n    ")]),_vm._v(" "),_c('VSnackbar',{attrs:{"color":"green","timeout":"4000","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.unassignedTaskNotification = false}}},'VBtn',attrs,false),[_vm._v("\n        Close\n      ")])]}}]),model:{value:(_vm.unassignedTaskNotification),callback:function ($$v) {_vm.unassignedTaskNotification=$$v},expression:"unassignedTaskNotification"}},[_vm._v("\n    A task has been unassigned from you.\n    ")]),_vm._v(" "),_c('VMenu',{attrs:{"offset-y":"","transition":"scroll-y-transition","content-class":"elevation-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBadge',{attrs:{"color":"red","overlap":"","value":_vm.tasks.length,"content":_vm.tasks.length}},[_c('VIcon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"data-testid":"nav-tasks-button"}},'VIcon',attrs,false),on),[_vm._v("\n          mdi-playlist-check\n        ")])],1)]}}])},[_vm._v(" "),_c('div',{staticClass:"elevation-0"},[_c('VCard',{staticClass:"mb-2"},[_c('VCardText',{staticClass:"py-0"},[_c('VSwitch',{staticClass:"py-4 my-0",attrs:{"label":"Include Snoozed","hide-details":"","dense":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.includeSnoozed),callback:function ($$v) {_vm.includeSnoozed=$$v},expression:"includeSnoozed"}}),_vm._v(" "),_c('VSwitch',{staticClass:"py-4 my-0",attrs:{"label":"Include Blocked","hide-details":"","dense":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.includeBlocked),callback:function ($$v) {_vm.includeBlocked=$$v},expression:"includeBlocked"}}),_vm._v(" "),_c('VSwitch',{staticClass:"py-4 my-0",attrs:{"label":"Include Completed","hide-details":"","dense":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.includeCompleted),callback:function ($$v) {_vm.includeCompleted=$$v},expression:"includeCompleted"}})],1)],1),_vm._v(" "),_c('UserTasks',{attrs:{"tasks":_vm.tasks}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }