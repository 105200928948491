//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    userInitials() {
      return this.$auth.user.firstName.charAt(0) + this.$auth.user.lastName.charAt(0)
    },
  },
  methods: {
    logout() {
      window.location.replace(this.$config.msLogoutUrl)
    },
  },
}
