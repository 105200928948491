import { GetterTree, ActionTree, MutationTree } from 'vuex'

import { LawFirmName } from '~/types/LawFirm'
import { MatterType } from '~/types/Lead'

export interface IntakeFormConfig {
  maidenName: { visible: boolean; required: boolean }
  aliases: { visible: boolean; required: boolean }
  maritalStatus: { visible: boolean; required: boolean }
  contacts: { visible: boolean; required: boolean }
  insurance: { visible: boolean; required: boolean }
  occupation: { visible: boolean; required: boolean }
  employmentStatus: { visible: boolean; required: boolean }
  employmentHistory: { visible: boolean; required: boolean }
  deceased: { visible: boolean; required: boolean }
  facilities: { visible: boolean; required: boolean }
  naturalChildren: { visible: boolean; required: boolean }
}

interface IntakeConfig {
  lawFirm: LawFirmName
  matterType: MatterType
  config: IntakeFormConfig
}

export const state = () => ({
  defaultIntakeFormConfig: {
    maidenName: { visible: false, required: false },
    aliases: { visible: false, required: false },
    maritalStatus: { visible: false, required: false },
    contacts: { visible: false, required: false },
    insurance: { visible: false, required: false },
    occupation: { visible: false, required: false },
    employmentStatus: { visible: false, required: false },
    employmentHistory: { visible: false, required: false },
    deceased: { visible: false, required: false },
    facilities: { visible: false, required: false },
    naturalChildren: { visible: false, required: false },
  },
  intakeConfigs: [
    {
      lawFirm: LawFirmName.PINTAS,
      matterType: MatterType.ZANTAC,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: true, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: false, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: true, required: false },
      },
    },
    {
      lawFirm: LawFirmName.PINTAS,
      matterType: MatterType.HERNIA,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: true, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: true, required: false },
        employmentHistory: { visible: false, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
    {
      lawFirm: LawFirmName.PINTAS,
      matterType: MatterType.ROUNDUP,
      config: {
        maidenName: { visible: false, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: true, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: false, required: false },
        naturalChildren: { visible: true, required: false },
      },
    },
    {
      lawFirm: LawFirmName.PALERMO,
      matterType: MatterType.ZANTAC,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: false, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
    {
      lawFirm: LawFirmName.PALERMO,
      matterType: MatterType.HERNIA,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: false, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
    {
      lawFirm: LawFirmName.PALERMO,
      matterType: MatterType.ROUNDUP,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: true, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
    {
      lawFirm: LawFirmName.DMA,
      matterType: MatterType.ZANTAC,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: false, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: false, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
    {
      lawFirm: LawFirmName.DMA,
      matterType: MatterType.PARAGARD,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: false, required: false },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: false, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: false, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: true, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
    {
      lawFirm: LawFirmName.ARP,
      matterType: MatterType.ROUNDUP,
      config: {
        maidenName: { visible: false, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: false },
        insurance: { visible: false, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: false, required: false },
        employmentHistory: { visible: true, required: false },
        deceased: { visible: true, required: false },
        facilities: { visible: false, required: false },
        naturalChildren: { visible: true, required: false },
      },
    },
    {
      lawFirm: LawFirmName.HMG,
      matterType: MatterType.HERNIA,
      config: {
        maidenName: { visible: true, required: false },
        aliases: { visible: true, required: false },
        maritalStatus: { visible: true, required: true },
        contacts: { visible: true, required: true },
        insurance: { visible: true, required: false },
        occupation: { visible: true, required: false },
        employmentStatus: { visible: true, required: true },
        employmentHistory: { visible: true, required: true },
        deceased: { visible: true, required: false },
        facilities: { visible: false, required: false },
        naturalChildren: { visible: false, required: false },
      },
    },
  ] as IntakeConfig[],
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  defaultIntakeFormConfig(state) {
    return state.defaultIntakeFormConfig
  },
  intakeConfigs(state) {
    return state.intakeConfigs
  },
  claimantRepresentative(_state, _getters, _rootState, rootGetters) {
    return rootGetters['resources/claimant-representatives/all']?.[0]
  },
  callContact: (_state, getters, _rootState, rootGetters) => {
    if (!rootGetters.lead) return
    if (getters.claimantRepresentative) return getters.claimantRepresentative

    return rootGetters.lead.claimant
  },
}

export const mutations: MutationTree<RootState> = {}

export const actions: ActionTree<RootState, RootState> = {}
