
















































































































































































import debounce from 'lodash/debounce'
import moment from 'moment'
import { format as formatPhoneNumber } from 'phone-fns'
import Vue from 'vue'

import { Conversation, ConversationChannel } from '~/types/Conversation'

export default Vue.extend({
  data: () => ({
    campaigns: [],
    searchFields: ['lead.claimant.firstName', 'lead.claimant.lastName', 'leadId', 'participants'],
  }),

  computed: {
    selectedCampaigns: {
      get() {
        return this.$store.getters['conversations/selectedCampaigns']
      },
      async set(selectedCampaigns) {
        await this.$store.dispatch('conversations/selectCampaigns', selectedCampaigns)
      },
    },
    search: {
      get() {
        return this.$store.getters['conversations/search']
      },
      set(search) {
        // @ts-ignore
        this.debouncedSearch(search)
      },
    },

    showResolved: {
      get() {
        return this.$store.getters['conversations/showResolved']
      },
      set() {
        this.$store.dispatch('conversations/toggleShowResolved')
      },
    },

    showAssignedToOthers: {
      get() {
        return this.$store.getters['conversations/showAssignedToOthers']
      },
      set() {
        this.$store.dispatch('conversations/toggleShowAssignedToOthers')
      },
    },

    showAssignedToMe: {
      get() {
        return this.$store.getters['conversations/showAssignedToMe']
      },
      set() {
        this.$store.dispatch('conversations/toggleShowAssignedToMe')
      },
    },

    showUnassigned: {
      get() {
        return this.$store.getters['conversations/showUnassigned']
      },
      set() {
        this.$store.dispatch('conversations/toggleShowUnassigned')
      },
    },

    missingLead: {
      get() {
        return this.$store.getters['conversations/showMissingLead']
      },
      set() {
        this.$store.dispatch('conversations/toggleShowMissingLead')
      },
    },

    conversations(): Conversation[] {
      return Object.values(this.$store.getters['conversations/all'] as Conversation[])
        .filter(c => c.assignedToUserId === this.$auth.user.id)
        .concat(
          Object.values(this.$store.getters['conversations/all'] as Conversation[]).filter(
            c => c.assignedToUserId !== this.$auth.user.id,
          ),
        )
        .sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
    },

    needsAction(): Conversation[] {
      return this.$store.getters['conversations/needsAction']
    },
  },

  async mounted() {
    // api call
    const response = await this.$axios.$get(`v1/campaigns?limit=50&page=1&sort%5B0%5D=name%2CASC`)
    this.campaigns = response.data
    const savedFilter = localStorage.getItem(this.$store.getters['conversations/savedFilterKey'])
    if (savedFilter)
      await this.$store.dispatch('conversations/selectCampaigns', JSON.parse(savedFilter))
    await this.$store.dispatch('conversations/getConversations')
  },

  methods: {
    async replaceConversation(oldId, newId) {
      await this.$store.dispatch('conversations/replaceConversation', { oldId, newId })
    },

    async openConversation(id) {
      await this.$store.dispatch('conversations/openConversation', { id })
    },

    async closeConversation(id) {
      await this.$store.dispatch('conversations/closeConversation', { id })
    },

    getConversationTitle(conversation: Conversation) {
      if (!conversation.lead || !conversation.lead.claimant || !conversation.lead.lawFirm) {
        if (conversation.channel === ConversationChannel.EMAIL)
          return conversation.participants.find(p => p !== 'support@mail.legalservicesupport.com')
        if (conversation.channel === ConversationChannel.SMS) {
          let phoneNumber = conversation.participants.find(p => p !== '+15614487828')
          if (phoneNumber) phoneNumber = formatPhoneNumber('+N-NNN-NNN-NNNN', phoneNumber)
          return phoneNumber
        }
      }

      if (conversation?.lead?.claimant)
        return `${conversation.lead.claimant.firstName} ${conversation.lead.claimant.lastName} - ${conversation.lead.matterType} - ${conversation.lead.lawFirm.name}`
    },

    getAssignee(conversation: Conversation) {
      if (!conversation.assignedToUser) return 'Unassigned'

      return `Assigned to: ${conversation.assignedToUser.firstName} ${conversation.assignedToUser.lastName}`
    },

    agoDate(date) {
      return moment(date).fromNow()
    },
    debouncedSearch: debounce(function(search) {
      // @ts-ignore
      this.$store.dispatch('conversations/search', search)
    }, 500),
  },
})
