import { RequestQueryBuilder } from '@nestjsx/crud-request'
import Vue from 'vue'

export const state = () => ({
  blockers: [],
  newBlockerNotification: false,
  unassignedBlockerNotification: false,
})

export const getters = {
  blockers: state => state.blockers,
  newBlockerNotification: state => state.newBlockerNotification,
  unassignedBlockerNotification: state => state.unassignedBlockerNotification,
}

export const mutations = {
  set(state, blockers) {
    state.blockers = blockers
  },

  add(state, blockers) {
    state.blockers.push(...blockers)
  },

  update(state, blocker) {
    const blockerIndex = state.blockers.findIndex(t => t.id === blocker.id)
    if (blockerIndex === -1) return

    Vue.set(state.blockers, blockerIndex, blocker)
  },

  newBlockerNotification(state, value) {
    state.newBlockerNotification = value
  },
  unassignedBlockerNotification(state, value) {
    state.unassignedBlockerNotification = value
  },
}

export const actions = {
  get({ dispatch }) {
    return dispatch('search')
  },

  refresh({ dispatch }) {
    return dispatch('search')
  },

  async search({ commit, rootState }) {
    const queryBuilder = RequestQueryBuilder.create()
      .setLimit(50)
      .setPage(1)
      // @ts-ignore
      .search({ assignedToId: rootState.auth.user.id, resolution: null })
      .setJoin([
        { field: 'task' },
        { field: 'lead' },
        { field: 'lead.claimant' },
        { field: 'lead.lawFirm' },
      ])

    const query = queryBuilder.query()
    // @ts-ignore
    const { data } = await this.$axios.$get(`v1/task-blockers?${query}`)

    commit('set', data)

    return data
  },

  setBlockerNotification({ commit }, { notification, value }) {
    commit(notification, value)
  },
}
