export const state = () => ({
  teamId: null,
})

export const getters = {
  teamId: state => state.teamId,
}

export const mutations = {
  set(state, teamId) {
    state.teamId = teamId
  },
}
