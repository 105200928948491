import { Plugin } from '@nuxt/types'

interface queues {
  getTaskNames(): string[]
  getTaskDisplayName(type: string): string | undefined
  getTaskRoute(task: object): string | undefined
  getTaskDescription(type: string): string | undefined
  getTaskStatus(task: any): string
}
declare module 'vue/types/vue' {
  interface Vue {
    $queues: queues
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $queues: queues
  }
  interface Context {
    $queues: queues
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $queues(message: string): void
  }
}

export enum LeadTaskStatus {
  NOT_NEEDED = 'not needed',
  NOT_READY = 'not ready',
  NOT_STARTED = 'not started',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
}

const tasks = {
  'claimant information': {
    displayName: 'Claimant Information',
    description: 'Complete claimant demographic and contact information.',
  },
  'claimant rep document': {
    displayName: 'Rep Document',
    description: 'A signed claimant representative document is obtained.',
  },
  questionnaire: {
    displayName: 'Questionnaire',
    description: 'Complete claimant lead questionnaire.',
  },
  retainer: {
    displayName: 'Retainer',
    description: 'A signed retainer is required.',
  },
  'medical release': {
    displayName: 'Medical Release',
    description: 'Add a valid medical release to the lead.',
  },
  'wet signature release': {
    displayName: 'Wet Signature Release',
    description:
      'Wet signature release requires reformatting to be used to create document requests.',
  },
  'send va release': {
    displayName: 'VA Release',
    description: 'Send a VA medical release for wet signature.',
  },
  receive_va_release: {
    displayName: 'VA Release Followup',
    description: 'Follow up with claimant on sent VA medical release.',
  },
  'medical events': {
    displayName: 'Medical Events',
    description: 'Medical Events',
  },
  'intake qc': {
    displayName: 'Intake QC',
    description: 'Ensure gathered intake information is complete, logical, and accurate.',
  },
  'pacer check': {
    displayName: 'Pacer Check',
    description: 'Check the Public Access to Court Electronic Records for a pre-existing case.',
  },
  'census plus': {
    displayName: 'Census Plus Form',
    description:
      'When flipped this switch indicates the census plus information for this lead has been added to the census plus spreadsheet.',
  },
  'census form qc': {
    displayName: 'Census Form QC',
    description: 'Quality control census form.',
  },
  'statute of limitations': {
    displayName: 'SOL',
    description: 'Enter the statute of limitations date for this lead.',
  },
  touhy: {
    displayName: 'Touhy',
    description:
      'When flipped this switch indicates the touhy request for this lead has been added to the spreadsheet.',
  },
  'document request creation': {
    displayName: 'Document Request',
    description: 'Document request creation.',
  },
  rejection: {
    displayName: 'Rejection',
    description: 'A document request was rejected.',
  },
  redaction: {
    displayName: 'Redaction',
    description:
      'Redact any sensitive information unrelated to the tort case from the medical records.',
  },
  review: {
    displayName: 'Review',
    description: 'Review medical record review for findings.',
  },
  'review qc': {
    displayName: 'Review QC',
    description: 'Quality control medical record review.',
  },
  'redacted document upload': {
    displayName: 'Redacted Document Upload',
    description:
      'When flipped this switch indicates the finalized redacted document has been uploaded into MDLCentrality.',
  },
  'case coordination closeout': {
    displayName: 'Case Coordination Signoff',
    description: 'When flipped this switch indicates all case coordination is complete.',
  },
  declination: {
    displayName: 'Declination',
    description: 'Send a declination letter to claimants marked for declination.',
  },
  'send pou affidavit': {
    displayName: 'POU Affidavit',
    description: 'Send POU affidavit notarization request.',
  },
  'receive pou affidavit': {
    displayName: 'POU Affidavit Followup',
    description: 'Follow up on sent POU affidavit notarization request.',
  },
  mailroom: {
    displayName: 'Mailroom',
    description:
      'Associate the document sent to Torticity via fax or mail (FTP) with the correct lead and/or document request',
  },
  'law firm response': {
    displayName: 'Law Firm Response',
    description: 'Lead is proven, enter response from law firm. Accepted/rejected and why.',
  },
  'claim prove out': {
    displayName: 'Claim Prove Out',
    description: 'Findings are organized into claims to determine if there is sufficient proof.',
  },
}

export const plugin = {
  getTaskNames() {
    return Object.keys(tasks)
  },
  getTaskDisplayName(type) {
    return tasks[type]?.displayName
  },
  getTaskRoute(task) {
    if (task.leadId) return `/leads/${task.leadId}/tasks/${task.id}`
    if (task.documentId) return `/documents/${task.documentId}/tasks/${task.id}`
  },
  getTaskDescription(type) {
    return tasks[type]?.description
  },
  getTaskStatus(queueTask): { text: string; color: string } {
    const blockerCount = queueTask.blockers
      ? queueTask.blockers.filter(b => b.resolvedAt === null).length
      : 0
    if (blockerCount > 0) return { text: `Blocked (${blockerCount})`, color: 'red' }
    const gray = '#A9A9A9'
    if (new Date().valueOf() < new Date(queueTask.snoozedUntil).valueOf())
      return { text: 'Snoozed', color: '#D7932E' }

    switch (queueTask.status) {
      case LeadTaskStatus.NOT_READY:
        return { text: 'Not Ready', color: gray }
      case LeadTaskStatus.NOT_STARTED:
        return { text: 'Active', color: 'green' }
      case LeadTaskStatus.IN_PROGRESS:
        return { text: 'Active', color: 'green' }
      case LeadTaskStatus.COMPLETED:
        return { text: 'Completed', color: gray }
      case LeadTaskStatus.NOT_NEEDED:
        return { text: 'Not Needed', color: gray }
    }
    return { text: '', color: gray }
  },
}

const queuesPlugin: Plugin = (_, inject) => {
  inject('queues', plugin)
}

export default queuesPlugin
