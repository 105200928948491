import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=5e8ee5eb&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=5e8ee5eb&lang=scss&scoped=true&"
import style1 from "./dashboard.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8ee5eb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DeprecationNotice: require('/home/node/components/DeprecationNotice.vue').default,NavUserTaskBlockers: require('/home/node/components/nav/NavUserTaskBlockers.vue').default,NavUserTasks: require('/home/node/components/nav/NavUserTasks.vue').default,NavMessenger: require('/home/node/components/nav/NavMessenger.vue').default,NewChat: require('/home/node/components/conversations/NewChat.vue').default,Chat: require('/home/node/components/conversations/Chat.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VBtn,VCol,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMain,VMenu,VRow,VSpacer,VTab,VTabs,VToolbarItems,VToolbarTitle})
