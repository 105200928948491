export const state = () => ({
  beta: true,
})

export const getters = {
  beta: state => state.beta,
}

export const mutations = {
  update(state, beta) {
    state.beta = beta
  },
}
