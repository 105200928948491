






























































































































































































































import mime from 'mime-types'
import moment from 'moment'
import Vue from 'vue'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

type Document = {
  id: string | null
  fileType: string | null
  fileName: string | null
  type: string | null
  subType: string | null
  template: string | null
  leadId: string | null
  key: string | null
  isEncrypted: boolean | null
  medicalEvents: any[] | null
  isReviewable: boolean
}

export default Vue.extend({
  name: 'DataTableDocuments',

  validations: {
    document: {
      type: {
        required,
      },
      subType: {
        required: requiredIf(document => document.type === 'Claimant Correspondence'),
      },
      fileName: document => {
        if (!document.id) return true
        const extension = mime.extension(document?.fileType).toLowerCase()
        const splitFileName = document.fileName.split('.')
        const fileExtension = splitFileName[splitFileName.length - 1].toLowerCase()
        if (fileExtension === extension) return true
        return false
      },
    },
  },

  props: {
    parentRoute: {
      type: String,
      required: false,
      default: '',
    },
    filter: {
      type: Object,
      required: false,
      default: null,
    },
    sort: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      required: false,
      default: () => [10],
    },
    creatable: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Documents',
    },
    matterType: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    showError: false,
    document: {
      id: null,
      fileType: null,
      fileName: null,
      type: null,
      subType: null,
      template: null,
      leadId: null,
      key: null,
      isEncrypted: null,
      medicalEvents: null,
      isReviewable: false,
    } as Document,

    form: {
      documentMedicalEvents: [] as string[],
    },

    selectedDocument: null as Document | null,

    dialog: false,
    password: null as string | null,
    showPassword: false,

    addDialog: false,

    loading: false,

    newTabLoading: false,

    headers: [
      {
        text: 'Name',
        value: 'fileName',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'Subtype',
        value: 'subType',
      },
      {
        text: 'Template',
        value: 'template',
      },
      {
        text: 'Created At',
        value: 'createdAt',
      },
      { text: '', value: 'actions', sortable: false },
    ],
  }),

  computed: {
    subTypeOptions() {
      if (!this.document?.type) return null
      return this.$store.getters.documentTypeSubTypes[this.document.type]
    },

    leadId(): string | null {
      return this?.document?.leadId
    },
    userRoles(): string[] {
      return this.$auth.user?.roles
    },
    editable(): boolean {
      return this.$auth.user?.roles?.some(value =>
        ['admin', 'mailroom', 'intake_outreach'].includes(value),
      )
    },

    documentTypes(): string[] {
      return this.$store.getters.documentTypeOptions
        .filter(type => type !== 'Invoice')
        .concat(['document-request'])
    },

    medicalEvents(): any[] {
      if (!this.document.leadId) return []
      return this.$store.getters['resources/medical-events/all']
    },
    fileNameError(): string[] {
      const errors: string[] = []
      if (!this.$v.document.fileName)
        errors.push(
          `File name must contain the extension '.${mime.extension(this.document?.fileType)}'`,
        )
      return errors
    },
  },
  watch: {
    async leadId(leadId) {
      if (!leadId) return

      await this.getMedicalEvents({
        search: { leadId },
        itemsPerPage: 50,
        join: [{ field: 'medicalFacility' }, { field: 'medicalEventType' }],
      })
    },
  },
  methods: {
    ...mapActions('resources/documents', ['delete', 'patch']),
    ...mapActions('resources/activity', {
      postActivity: 'post',
    }),
    ...mapActions('resources/medical-events', {
      getMedicalEvents: 'get',
    }),

    canRemoveDocument(doc: any = null): boolean {
      if (doc && doc?.type === 'Invoice') {
        return this?.userRoles?.includes('finance')
      }

      return this.userRoles?.some(value => ['admin', 'mailroom'].includes(value))
    },

    formatMedicalEventName(medicalEvent) {
      if (!medicalEvent.medicalEventType)
        return `${medicalEvent.type} - ${medicalEvent.medicalFacility.name} (${this.formatDate(
          medicalEvent.date,
        )})`

      return `${medicalEvent.medicalEventType.name} - ${
        medicalEvent.medicalFacility.name
      } (${this.formatDate(medicalEvent.date)})`
    },

    formatDate(date) {
      return moment.utc(date).format('MMM YYYY')
    },

    async remove(document) {
      let confirmation = 'Are you sure?'
      if (document.type === 'Invoice') {
        confirmation = `${confirmation} Deleting this document will also delete any connected document request invoices.`
      }
      if (window.confirm(confirmation)) {
        try {
          await this.delete({
            data: document,
            options: { parentRoute: this.parentRoute },
          })
        } catch (e) {
          // @ts-ignore
          if (e?.response?.status === 400) {
            this.showError = true
          }
        }
      }
    },

    async open(document: Document) {
      // prevent user from opening too many tabs
      if (this.newTabLoading) return

      window.open(
        (await this.$axios.$get('v1/documents/signed-urls', { params: { key: document.key } })).url,
        '_blank',
      )
      this.newTabLoading = false
    },

    annotate(document: Document) {
      const routeData = this.$router.resolve({
        name: 'pdf-editor-documentId',
        params: { documentId: document.id as string },
      })
      window.open(routeData.href, '_blank')
    },

    edit(document: Document) {
      this.selectedDocument = { ...document }
      const {
        id,
        fileType,
        fileName,
        subType,
        template,
        leadId,
        key,
        isEncrypted,
        medicalEvents,
        isReviewable,
      } = document
      let { type } = document
      if (type !== 'Invoice' && !this.documentTypes.includes(type as string)) {
        type = null
      }

      this.document = {
        id,
        fileType,
        fileName,
        type,
        subType,
        template,
        leadId,
        key,
        isEncrypted,
        medicalEvents,
        isReviewable,
      }
      this.form.documentMedicalEvents = this.document.medicalEvents
        ? this.document.medicalEvents.map(me => me.id)
        : []
      this.dialog = true
    },

    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      try {
        this.loading = true

        let type = this.document.type

        if (this.matterType === '3M EarPlugs' && type === 'Medical Records')
          type = 'Unredacted Medical Records'

        await this.patch({
          data: {
            ...this.document,
            type,
            medicalEvents: this.form.documentMedicalEvents.map(id => ({ id })),
          },
        })

        if (this.selectedDocument?.type !== this.document.type) {
          const data = {
            user: this.$auth.user,
            type: 'documentTypeChange',
            detail: { from: this.selectedDocument?.type, to: this.document.type },
            secondaryRelationship: 'document',
            secondaryRelationshipId: this.document.id,
          }

          await this.postActivity({
            data,
            options: { parentRoute: `leads/${this.document.leadId}` },
          })
        }

        if (this.document.isEncrypted && this.password?.trim()) {
          await this.$axios.$post(`v1/documents/${this.document.id}/unlock`, {
            password: this.password.trim(),
          })
        }

        this.closeDialog()
      } finally {
        this.loading = false
      }
    },

    closeDialog() {
      this.selectedDocument = null
      this.document = {
        id: null,
        fileType: null,
        fileName: null,
        type: null,
        subType: null,
        template: null,
        leadId: null,
        key: null,
        isEncrypted: null,
        medicalEvents: null,
        isReviewable: false,
      }
      this.dialog = false
      this.$v.$reset()
    },

    moment,
  },
})
