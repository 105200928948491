import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface State {}

export const state = (): State => ({})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  titlePrefix: (_state, _getters, _rootState, rootGetters) => {
    const needsAction = rootGetters['conversations/needsAction']

    if (!needsAction.length) return ''

    return `(${needsAction.length}) `
  },

  titleSuffix: () => {
    return '· Dockit'
  },
  getTitle(_state, getters) {
    return pageName => `${getters.titlePrefix} ${pageName} ${getters.titleSuffix}`
  },
}

export const mutations: MutationTree<RootState> = {}

export const actions: ActionTree<RootState, RootState> = {}
