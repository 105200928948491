// This module houses new facilities which have not previously been persisted to the backend

import { v4 as uuid } from 'uuid'
import Vue from 'vue'

export const state = () => ({
  facilities: [],
})

export const getters = {
  facilities: state => state.facilities,
}

export const mutations = {
  add(state, medicalFacility) {
    state.facilities.push(medicalFacility)
  },
  update(state, { uuid, data }) {
    Vue.set(
      state.facilities,
      state.facilities.findIndex(f => f.uuid === uuid),
      {
        ...state.facilities.find(f => f.uuid === uuid),
        ...data,
      },
    )
  },
  remove(state, uuid) {
    const index = state.facilities.findIndex(f => f.uuid === uuid)
    state.facilities.splice(index, 1)
  },
}

export const actions = {
  async get({ _commit }, value) {
    // @ts-ignore
    const facilities = await this.$axios.$get('medical-facilities', { params: { name: value } })
    return facilities.map(facility => {
      const phoneNumber = facility.phoneNumbers.find(p => p.type === 'office')
      const address = facility.addresses.find(a => a.type === 'physical')
      return {
        // spread address first to avoid overriding facility id
        ...address,
        uuid: uuid(),
        id: facility.id,
        name: facility.name,
        phoneNumber: phoneNumber?.phoneNumber ?? null,
        createdAt: facility.createdAt,
      }
    })
  },
  add({ commit }, facility) {
    if (!facility) {
      facility = {
        uuid: uuid(),
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
      }
    }
    commit('add', facility)

    return facility
  },

  remove({ commit }, uuid) {
    commit('remove', uuid)
  },

  async save({ commit, getters }, uuid) {
    const medicalFacility = getters.facilities.find(f => f.uuid === uuid)

    const { name, phoneNumber, address1, address2, city, state, zipCode } = medicalFacility
    // @ts-ignore
    const { data } = await this.$axios.post(`/medical-facilities`, {
      name,
      addresses: [
        {
          address1,
          address2,
          city,
          state,
          zipCode,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: phoneNumber.replace(/\D/g, ''),
        },
      ],
    })

    commit('update', {
      uuid: medicalFacility.uuid,
      data,
    })

    return data
  },
}
