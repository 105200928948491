import { RequestQueryBuilder, QueryJoin, QueryJoinArr } from '@nestjsx/crud-request'
import Vue from 'vue'

export default resources => store => {
  for (const resource of resources) {
    const route = routeMapper(resource)

    store.registerModule([`resources/${resource}`], {
      namespaced: true,

      state: () => ({ items: [], total: 0, mostRecentParams: null }),

      getters: {
        all(state) {
          return state.items
        },
        total(state) {
          return state.total
        },
        mostRecentParams(state) {
          return state.mostRecentParams
        },
      },

      // Mutations are not meant to be used externally
      mutations: {
        mostRecentParams(state, mostRecentParams) {
          state.mostRecentParams = mostRecentParams
        },
        setTotal(state, value) {
          state.total = value
        },

        add(state, value) {
          if (Array.isArray(value)) return state.items.push(...value)

          state.items.push(value)

          state.total++
        },

        replace(state, value) {
          if (Array.isArray(value)) {
            state.items = value
            return
          }
          state.items = [value]
        },

        update(state, { id, data }) {
          if (!state.items.find(f => f.id === id)) return
          Vue.set(
            state.items,
            state.items.findIndex(f => f.id === id),
            {
              ...state.items.find(f => f.id === id),
              ...data,
            },
          )
        },

        remove(state, id) {
          const index = state.items.findIndex(r => r.id === id)
          state.items.splice(index, 1)
          state.total--
        },
      },

      actions: {
        get(
          { commit, dispatch },
          {
            sort = [],
            page = 1,
            itemsPerPage = 10,
            search = false,
            join = [],
            parentRoute = null,
            version = 'v1',
            select = null,
          } = {
            sort: [],
            page: 1,
            itemsPerPage: 10,
            search: false,
            join: [],
            parentRoute: null,
            version: 'v1',
            select: null,
          },
        ) {
          const queryBuilder = RequestQueryBuilder.create()
            .setLimit(itemsPerPage)
            .setPage(page)
            // @ts-ignore
            .search(search)
            // @ts-ignore
            .sortBy(sort)

          if (join?.length) {
            queryBuilder.setJoin(join)
          }

          if (select) {
            // @ts-ignore
            queryBuilder.select(select)
          }

          const queryString = queryBuilder.query()

          commit('mostRecentParams', { queryBuilder, queryString, parentRoute, version })

          return dispatch('search', { parentRoute, queryString, version })
        },

        refresh({ dispatch, getters }) {
          const parentRoute = getters?.mostRecentParams?.parentRoute ?? null
          const queryString = getters?.mostRecentParams?.queryString ?? null
          const version = getters?.mostRecentParams?.version ?? null

          return dispatch('search', { parentRoute, queryString, version })
        },

        async search({ commit }, params) {
          try {
            // @ts-ignore
            const result = await this.$axios.$get(
              `${params.version}/${params.parentRoute ? params.parentRoute + '/' : ''}${route}?${
                params.queryString
              }`,
            )

            commit('replace', result.data)
            commit('setTotal', result.total)
            return result
          } catch (err) {
            // This seems to be what might be hiding the cause of several sentry issues.
            // We may want to set a default return value
            // @ts-ignore
            this.$sentry.captureException(err)
            commit('replace', [])
            commit('setTotal', 0)
          }
        },

        async getById(
          { commit },
          options:
            | string
            | number
            | { id: number; join: QueryJoin | QueryJoinArr | Array<QueryJoin | QueryJoinArr> },
        ) {
          let id
          const queryBuilder = RequestQueryBuilder.create()
          if (typeof options === 'number' || typeof options === 'string') {
            id = options
          } else {
            id = options.id
            if (options?.join) {
              queryBuilder.setJoin(options.join)
            }
          }

          const queryString = queryBuilder.query() ? '?' + queryBuilder.query() : ''
          // @ts-ignore
          const result = await this.$axios.$get(`v1/${resource}/${id}${queryString}`)
          commit('replace', result)
          return result
        },

        async post({ commit }, { data, options = { parentRoute: null, join: [] } }) {
          const queryBuilder = RequestQueryBuilder.create()

          if (options.join?.length) {
            queryBuilder.setJoin(options.join)
          }

          const queryString = queryBuilder.query()
          // @ts-ignore
          const result = await this.$axios.$post(
            `v1/${options.parentRoute ? options.parentRoute + '/' : ''}${route}${
              queryString.length > 0 ? `?${queryString}` : ''
            }`,
            data,
          )

          commit('add', result)
          return result
        },

        async patch({ commit }, { data, options = { parentRoute: null, join: [] } }) {
          const queryBuilder = RequestQueryBuilder.create()

          if (options.join?.length) {
            queryBuilder.setJoin(options.join)
          }

          const queryString = queryBuilder.query()
          // @ts-ignore
          const result = await this.$axios.$patch(
            `v1/${options.parentRoute ? options.parentRoute + '/' : ''}${route}/${data.id}${
              queryString.length > 0 ? `?${queryString}` : ''
            }`,
            data,
          )

          commit('update', { id: data.id, data: result })

          return result
        },

        async delete({ commit }, { data, options = { parentRoute: null } }) {
          // @ts-ignore
          await this.$axios.$delete(
            `v1/${options.parentRoute ? options.parentRoute + '/' : ''}${route}/${data.id}`,
          )

          commit('remove', data.id)
        },
      },
    })
  }
}

function routeMapper(resource): string {
  switch (resource) {
    case 'person-addresses':
      return 'addresses'
    case 'person-contacts':
      return 'contacts'
    case 'person-email-addresses':
      return 'email-addresses'
    case 'person-employment-history':
      return 'employment-history'
    case 'person-insurance-policies':
      return 'insurance-policies'
    case 'person-phone-numbers':
      return 'phone-numbers'
    default:
      return resource
  }
}
