var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VRow',{staticClass:"message"},[_c('VCol',{staticClass:"pa-0",class:{
      'offset-4': _vm.isInternalMessage(_vm.message),
      external: !_vm.isInternalMessage(_vm.message),
      internal: _vm.isInternalMessage(_vm.message),
    },attrs:{"cols":"8"}},[_c('div',{staticClass:"message-text"},[_c('b',[_vm._v(_vm._s(_vm.subject))]),_vm._v(" "),_c('pre',{staticClass:"word-wrap"},[_vm._v(_vm._s(_vm.text))])]),_vm._v(" "),_c('VTooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date pointer"},'div',attrs,false),on),[_vm._v("\n          "+_vm._s(_vm.agoDate(_vm.message.createdAt))+"\n        ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.message.createdAt)))])]),_vm._v(" "),(_vm.attachments.length)?[_c('b',[_vm._v("Attachments:")]),_vm._v(" "),_vm._l((_vm.attachments),function(attachment,i){return _c('div',{key:i},[_c('a',{on:{"click":function($event){return _vm.viewAttachment(attachment.document)}}},[_vm._v(_vm._s(attachment.document.fileName))])])})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }