import { Lead } from './Lead'
import { Message } from './Message'
import { User } from './User'

export enum ConversationChannel {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum ConversationDisposition {
  SCHEDULED_CALL_BACK = 'scheduled_call_back',
  NOT_INTERESTED = 'not_interested',
  RETAINER_SIGNED = 'retainer_signed',
  WRONG_NUMBER = 'wrong_number',
  CLOSED = 'closed',
}

export interface Conversation {
  id: string
  lead?: Lead
  leadId: string
  messages: Message[]
  assignedToUserId?: number
  assignedToUser?: User
  participants: string[]
  channel: ConversationChannel
  assignedAt?: Date
  dispositionedByUserId?: number
  dispositionedByUser?: User
  dispositionedAt?: Date
  disposition: ConversationDisposition
  createdAt: Date
  updatedAt: Date
}
