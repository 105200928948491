//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    includeSnoozed: false,
    includeCompleted: false,
    includeBlocked: false,
  }),

  computed: {
    tasks() {
      return this.$store.getters['user-tasks/tasks']
    },
    newTaskNotification: {
      get() {
        return this.$store.getters['user-tasks/newTaskNotification']
      },
      set(value) {
        this.$store.dispatch('user-tasks/setTaskNotification', {
          notification: 'newTaskNotification',
          value,
        })
      },
    },
    unassignedTaskNotification: {
      get() {
        return this.$store.getters['user-tasks/unassignedTaskNotification']
      },
      set(value) {
        this.$store.dispatch('user-tasks/setTaskNotification', {
          notification: 'unassignedTaskNotification',
          value,
        })
      },
    },
  },

  watch: {
    async includeSnoozed() {
      await this.getUserTasks()
    },
    async includeCompleted() {
      await this.getUserTasks()
    },
    async includeBlocked() {
      await this.getUserTasks()
    },
  },

  async mounted() {
    await this.getUserTasks()
  },

  methods: {
    async getUserTasks() {
      await this.$store.dispatch('user-tasks/get', {
        includeSnoozed: this.includeSnoozed,
        includeCompleted: this.includeCompleted,
        includeBlocked: this.includeBlocked,
      })
    },
  },
}
