export const state = () => ({
  leads: [],
})

export const getters = {
  leads: state => state.leads,
}

export const mutations = {
  add(state, leads) {
    state.leads = leads
  },
}

export const actions = {
  async getLeads({ commit }) {
    // @ts-ignore
    const { data } = await this.$axios.get('leads')

    commit('add', data)
  },

  async getLead({ commit }, id) {
    // @ts-ignore
    const { data } = await this.$axios.get(`leads/${id}`)

    commit('add', [data])
  },
}
