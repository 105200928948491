export const state = () => ({
  currentPage: null,
  selectedDocument: null,
  selectedKeyPhrase: null,
  selectedRedactedPhrase: null,
  keyPhrases: [],
  redactedPhrases: [],
  unsavedChanges: false,
})

export const getters = {
  currentPage: state => state.currentPage,

  selectedDocument: state => state.selectedDocument,

  selectedKeyPhrase: state => state.selectedKeyPhrase,

  selectedRedactedPhrase: state => state.selectedRedactedPhrase,

  documentKeyPhrases: state => {
    return state.keyPhrases
  },

  documentRedactedPhrases: state => {
    return state.redactedPhrases
  },

  unsavedChanges: state => state.unsavedChanges,
}

export const actions = {
  setCurrentPage({ commit }, pageNumber) {
    commit('setCurrentPage', pageNumber)
  },

  setSelectedDocument({ commit, getters, dispatch }, document) {
    if (getters.selectedDocument?.id !== document?.id) {
      commit('setSelectedKeyPhrase', null)
      commit('setSelectedRedactedPhrase', null)
    }
    commit('setSelectedDocument', document)
    if (document) {
      dispatch('fetchPhrases', document.id)
    } else {
      commit('setKeyPhrases', [])
      commit('setRedactedPhrases', [])
    }
  },

  setSelectedKeyPhrase({ commit }, keyPhrase) {
    commit('setSelectedKeyPhrase', keyPhrase)
  },

  setSelectedRedactedPhrase({ commit }, redactedPhrase) {
    commit('setSelectedRedactedPhrase', redactedPhrase)
  },

  setUnsavedChanges({ commit }, unsavedChanges) {
    commit('setUnsavedChanges', unsavedChanges)
  },

  async fetchPhrases({ commit }, documentId: number) {
    // @ts-ignore
    const { data } = await this.$axios.$get(`v1/documents/${documentId}/key-phrases`)
    commit('setKeyPhrases', data)

    // @ts-ignore
    const response = await this.$axios.$get(`v1/documents/${documentId}/redacted-phrases`)
    commit('setRedactedPhrases', response.data)
  },
}

export const mutations = {
  setCurrentPage(state, pageNumber) {
    state.currentPage = pageNumber
  },

  setSelectedDocument(state, document) {
    state.selectedDocument = document
  },

  setSelectedKeyPhrase(state, keyPhrase) {
    state.selectedKeyPhrase = keyPhrase
  },

  setSelectedRedactedPhrase(state, redactedPhrase) {
    state.selectedRedactedPhrase = redactedPhrase
  },

  setKeyPhrases(state, keyPhrases) {
    state.keyPhrases = keyPhrases
  },

  setRedactedPhrases(state, redactedPhrases) {
    state.redactedPhrases = redactedPhrases
  },

  setUnsavedChanges(state, unsavedChanges) {
    state.unsavedChanges = unsavedChanges
  },
}
