//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    routeName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deprecationNotice: false,
  }),
  watch: {
    routeName: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return
        if ([].includes(newValue)) {
          this.deprecationNotice = true
          return
        }

        this.deprecationNotice = false
      },
      immediate: true,
    },
  },
}
