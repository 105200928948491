var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VSnackbar',{attrs:{"color":"green","timeout":"4000","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.newBlockNotification = false}}},'VBtn',attrs,false),[_vm._v("\n        Close\n      ")])]}}]),model:{value:(_vm.newBlockNotification),callback:function ($$v) {_vm.newBlockNotification=$$v},expression:"newBlockNotification"}},[_vm._v("\n    A new task blocker has been assigned to you.\n    ")]),_vm._v(" "),_c('VSnackbar',{attrs:{"color":"green","timeout":"4000","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.unassignedBlockerNotification = false}}},'VBtn',attrs,false),[_vm._v("\n        Close\n      ")])]}}]),model:{value:(_vm.unassignedBlockerNotification),callback:function ($$v) {_vm.unassignedBlockerNotification=$$v},expression:"unassignedBlockerNotification"}},[_vm._v("\n    A task blocker has been unassigned from you.\n    ")]),_vm._v(" "),_c('VMenu',{attrs:{"offset-y":"","transition":"scroll-y-transition","content-class":"elevation-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBadge',{attrs:{"color":"red","overlap":"","value":_vm.blockers.length,"content":_vm.blockers.length}},[_c('VIcon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"data-testid":"nav-blockers-button"}},'VIcon',attrs,false),on),[_vm._v("\n          mdi-alert\n        ")])],1)]}}])},[_vm._v(" "),_c('div',{staticClass:"elevation-0"},[_c('VCard',{staticClass:"mb-2"},[_c('VCardText',{staticClass:"py-0"},[_c('UserBlockers',{attrs:{"blockers":_vm.blockers}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }