import { RequestQueryBuilder } from '@nestjsx/crud-request'
import Vue from 'vue'

export const state = () => ({
  tasks: [],
  newTaskNotification: false,
  unassignedTaskNotification: false,
  mostRecentQuery: null,
})

export const getters = {
  tasks: state => state.tasks,
  newTaskNotification: state => state.newTaskNotification,
  unassignedTaskNotification: state => state.unassignedTaskNotification,
  mostRecentQuery: state => state.mostRecentQuery,
}

export const mutations = {
  set(state, tasks) {
    state.tasks = tasks
  },

  add(state, tasks) {
    state.tasks.push(...tasks)
  },

  mostRecentQuery(state, mostRecentQuery) {
    state.mostRecentQuery = mostRecentQuery
  },

  update(state, task) {
    const taskIndex = state.tasks.findIndex(t => t.id === task.id)
    if (taskIndex === -1) return

    Vue.set(state.tasks, taskIndex, task)
  },

  newTaskNotification(state, value) {
    state.newTaskNotification = value
  },
  unassignedTaskNotification(state, value) {
    state.unassignedTaskNotification = value
  },
}

export const actions = {
  get({ commit, dispatch }, { includeSnoozed, includeCompleted, includeBlocked }) {
    const queryBuilder = RequestQueryBuilder.create()
      .setLimit(50)
      .setPage(1)
      // @ts-ignore
      .search({ includeSnoozed, includeCompleted, includeBlocked })

    const query = queryBuilder.query()
    commit('mostRecentQuery', query)

    return dispatch('search', query)
  },

  refresh({ dispatch, getters }) {
    return dispatch('search', getters.mostRecentQuery)
  },

  async search({ commit }, query) {
    if (!query) return
    // @ts-ignore
    const { data } = await this.$axios.$get(`v1/lead-tasks/assigned?${query}`)

    commit('set', data)

    return data
  },

  setTaskNotification({ commit }, { notification, value }) {
    commit(notification, value)
  },
}
