//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'ResourceAutocomplete',
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    claimantRepFields: {
      type: Array,
      default: () => [],
    },
    searchFields: {
      type: Array,
      required: true,
    },
    suffixFields: {
      type: Array,
      default: () => [],
    },
    join: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      required: false,
      default: null,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefetch: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },

  data: () => ({
    isLoading: false,
    search: '',
    searchResults: [],
  }),

  computed: {
    mappedValue() {
      if (!this.value) return
      return {
        value: this.value,
        text: this.searchFields.map(field => get(this.value, field)).join(' '),
      }
    },
    items() {
      if (!this.value) return this.searchResults

      return [this.mappedValue]
    },
  },

  watch: {
    search(value, prev) {
      if (!value || (this.value && this.value.text === value)) return

      const trimmedValue = value.trim()

      if (trimmedValue === '' || trimmedValue === prev) return

      this.fetch(trimmedValue)
    },
  },

  async mounted() {
    if (this.prefetch) {
      this.searchResults =
        (
          await this.$api.get(this.resource, {
            join: this.join.map(field => ({ field })),
            itemsPerPage: 50,
          })
        )?.data?.map(item => ({
          value: item,
          text: this.searchFields.map(field => get(item, field)).join(' '),
        })) ?? []
    }
  },

  methods: {
    clearSearch() {
      this.searchResults = []
      this.search = ''
      this.$emit('input', '')
      this.$emit('click:clear')
    },
    inputHandler(event) {
      this.$emit('input', event?.value)
    },
    fetch: debounce(async function(search) {
      if (!search) return
      this.isLoading = true

      const join = this.join.map(field => ({ field }))
      const generateSearchText = item => {
        const searchText = this.searchFields.map(field => get(item, field)).join(' ')
        const suffixText = this.claimantRepFields
          .map(field => get(item, field))
          .join(' ')
          .trim()

        return `${searchText}${isEmpty(suffixText) ? '' : ' - ' + suffixText}`
      }

      try {
        this.searchResults =
          (
            await this.$api.get(this.resource, {
              search: {
                $and: search.split(' ').map(word => ({
                  $or: this.searchFields
                    .map(field => {
                      if (field === 'id') {
                        const numbers = word.match(/(\d[\d.]*)/g)
                        if (!numbers?.length) return null
                        return { id: Number(numbers[0]) }
                      }
                      return { [field]: { $contL: word } }
                    })
                    .filter(f => !!f),
                })),
                ...this.filter,
              },
              join,
              itemsPerPage: 50,
            })
          )?.data?.map(item => ({
            value: item,
            text: generateSearchText(item),
          })) ?? []
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.isLoading = false
      }
    }, 500),
  },
}
