//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    includeSnoozed: false,
    includeCompleted: false,
    includeBlocked: false,
  }),

  computed: {
    blockers() {
      return this.$store.getters['user-blockers/blockers']
    },
    newBlockNotification: {
      get() {
        return this.$store.getters['user-blockers/newBlockerNotification']
      },
      set(value) {
        this.$store.dispatch('user-blockers/setBlockerNotification', {
          notification: 'newBlockerNotification',
          value,
        })
      },
    },
    unassignedBlockerNotification: {
      get() {
        return this.$store.getters['user-blockers/unassignedBlockerNotification']
      },
      set(value) {
        this.$store.dispatch('user-blockers/setBlockerNotification', {
          notification: 'unassignedBlockerNotification',
          value,
        })
      },
    },
  },

  async mounted() {
    await this.getUserBlockers()
  },

  methods: {
    async getUserBlockers() {
      await this.$store.dispatch('user-blockers/get')
    },
  },
}
