export default async function({ route, redirect, $axios, $sentry }) {
  const routeToTaskTypeMap = {
    'leads-id-case-coordination-closeout': 'case coordination closeout',
    'leads-id-census-plus': 'census plus',
    'leads-id-claimant-information': 'claimant information',
    'leads-id-document-requests': 'document request creation',
    'leads-id-intake-qc': 'intake qc',
    'leads-id-law-firm-approval': 'law firm response',
    'leads-id-medical-events': 'medical events',
    'leads-id-medical-release': 'medical release',
    'leads-id-pacer-check': 'pacer check',
    'leads-id-questionnaire': 'questionnaire',
    'leads-id-retainer': 'retainer',
    'leads-id-mail-medical-release': 'send va release',
    'leads-id-declinations-new': 'declination',
    'leads-id-declinations': 'declination',
    'leads-id-notarizations-new': 'send pou affidavit',
    'leads-id-notarizations': 'receive pou affidavit',
  }

  if (!Object.keys(routeToTaskTypeMap).includes(route.name)) return

  const leadId = route.params.id

  const redirectUrl = `/leads/${leadId}`

  const taskType = routeToTaskTypeMap[route.name]

  if (!taskType) return redirect(redirectUrl)

  const taskId = await getTaskId(leadId, taskType)

  if (!taskId) await getTaskId(leadId, 'claimant information')

  if (!taskId) return redirect(redirectUrl)

  return redirect(redirectUrl + `/tasks/${taskId}`)

  async function getTaskId(leadId, type) {
    if (!leadId || !type) return
    try {
      const { data: tasks } = await $axios.$get('v1/lead-tasks', {
        params: {
          s: { leadId, type },
          limit: 1,
        },
      })

      if (tasks.length) return tasks[0].id
    } catch (e) {
      $sentry.captureException(e)
    }
  }
}
