//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed, useContext } from '@nuxtjs/composition-api'
import axios from 'axios'
import mime from 'mime-types'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import sanitize from 'sanitize-filename'
import { useQuery } from 'vue-query'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  layout: 'dashboard',
  middleware: ['auth'],
  props: {
    preselectedLeadId: {
      type: String,
      required: false,
      default: null,
    },
    file: {
      type: Object,
      required: false,
      default: null,
    },
    document: {
      type: Object,
      required: false,
      default: null,
    },
    showDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  validations: {
    file: {
      validFileSize: file => {
        if (file && (typeof file.fileSize !== `number` || file.fileSize <= 0)) return false
        return true
      },
      invoiceMustBePdf: (file, { form }) => {
        if (file && form?.documentType === 'Invoice') return file.fileType === 'application/pdf'
        return true
      },
      invoiceMaxSize: (file, { form }) => {
        if (file && form?.documentType === 'Invoice') return file.fileSize < 1500000
        return true
      },
      invoiceMaxPages: (file, { form }) => {
        if (file && form?.documentType === 'Invoice')
          return new Promise(resolve => {
            try {
              getPdfPageCount(file.file).then(pageCount => {
                if (!pageCount) resolve(true) // filereader api not available in cypress
                resolve(pageCount <= 6)
              })
            } catch (error) {
              resolve(true)
            }
          })
        return true
      },
      fileNameExtension: (file, { form }) => {
        if (!form.customFileName) return true
        if (!file) return true
        const splitFileName = form.customFileName.split('.')
        const fileExtension = splitFileName[splitFileName.length - 1].toLowerCase()
        if (fileExtension === file?.fileExtension.toLowerCase()) return true
        return false
      },
    },
    document: {
      fileNameExtension: (document, { form }) => {
        if (!form.customFileName) return true
        if (!document) return true
        const splitFileName = form.customFileName.split('.')
        const fileExtension = splitFileName[splitFileName.length - 1].toLowerCase()
        const docExt = mime.extension(document?.fileType).toLowerCase()
        if (fileExtension === docExt) return true
        return false
      },
    },
    leadId: { required },
    form: {
      documentType: { required },
      comment: { required: requiredIf(form => form.documentRequestStatus === 'rejected') },
      documentRequest: {
        required: requiredIf(function(form) {
          return (
            this.$store.getters.documentTypesConfig?.[this.form?.documentType]
              ?.requireDocumentRequestUpdate && !form.medicalEvents?.length
          )
        }),
      },
      documentRequestRejectedReason: {
        required: requiredIf(function(form) {
          return form.documentRequest && form.documentRequestStatus === 'rejected'
        }),
      },
      documentRequestClosedReason: {
        required: requiredIf(function(form) {
          return (
            this.$store.getters.documentTypesConfig?.[this.form?.documentType]
              ?.requireDocumentRequestUpdate &&
            form.documentRequest &&
            form.documentRequestStatus === 'closed'
          )
        }),
      },
      documentRequestStatus: {
        required: requiredIf(function(form) {
          return (
            this.$store.getters.documentTypesConfig?.[this.form?.documentType]
              ?.requireDocumentRequestUpdate && form.documentRequest
          )
        }),
      },
      medicalEvents: {
        required: requiredIf(function(form) {
          return (
            this.$store.getters.documentTypesConfig[this.form?.documentType]
              ?.requireMedicalEvents && !form.documentRequest
          )
        }),
      },
    },
  },

  setup() {
    const { $api } = useContext()

    // data
    const selectedLead = ref(null)

    // computed
    const leadId = computed(() => {
      if (selectedLead.value) {
        return selectedLead.value.id
      }

      return null
    })

    const itemsPerPage = 50

    const documentRequestJoin = [
      { field: 'medicalFacility' },
      { field: 'medicalFacility.copyService' },
      { field: 'lead' },
      { field: 'claimant' },
      { field: 'medicalEvents' },
    ]

    const search = computed(() => {
      if (selectedLead.value) {
        return { leadId: selectedLead.value.id }
      }
      return null
    })

    const { refetch: refetchDocumentRequests, data: documentRequestAll } = useQuery(
      ['document-requests', search.value, documentRequestJoin],
      () =>
        search.value
          ? $api.get('document-requests', {
              search: search.value,
              itemsPerPage,
              join: documentRequestJoin,
            })
          : [],
    )

    const medicalEventsJoin = [{ field: 'medicalFacility' }, { field: 'medicalEventType' }]

    const { refetch: refetchMedicalEvents, data: medicalEvents } = useQuery(
      ['medical-events', search, medicalEventsJoin],
      () =>
        search.value
          ? $api.get('medical-events', {
              search: search.value,
              itemsPerPage,
              join: medicalEventsJoin,
            })
          : [],
    )

    return {
      refetchDocumentRequests,
      documentRequestAll,
      refetchMedicalEvents,
      medicalEvents,
      selectedLead,
      leadId,
    }
  },

  data: () => ({
    showError: false,
    additionalInvoice: false,
    form: {
      documentType: null,
      documentRequestStatus: null,
      medicalEvents: [],
      customFileName: null,
    },
    existingInvoiceDocumentId: null,
    loading: false,
    complete: false,
    pdfPageCount: null,
    isEncrypted: false,
    password: null,
    showPassword: false,
    claimantRepFields: [
      'claimant.claimantRepresentatives[0].firstName',
      'claimant.claimantRepresentatives[0].lastName',
    ],
    searchFields: [
      'id',
      'matterType',
      'claimant.firstName',
      'claimant.lastName',
      'claimant_claimantRepresentatives.firstName',
      'claimant_claimantRepresentatives.lastName',
      'lawFirm.name',
    ],
  }),

  computed: {
    subTypeOptions() {
      if (!this.form?.documentType) return null
      return this.$store.getters.documentTypeSubTypes[this.form.documentType]
    },

    fileName() {
      return this.file?.file.name ?? this.document.fileName
    },
    medicalEventsItems() {
      if (!this.medicalEvents || !this.medicalEvents.data) return []
      return this.medicalEvents.data.map(me => ({
        text: this.formatMedicalEventName(me),
        value: me.id,
      }))
    },
    docRequestsWithMedEventTypes() {
      if (!this.documentRequestAll || !this.documentRequestAll.data) return []
      return this.documentRequestAll.data.map(d => {
        const medicalEvents =
          d?.medicalEvents && d?.medicalEvents?.data?.length
            ? this.medicalEvents?.data.find(m => d?.medicalEvents[0]?.id === m.id)
            : []

        return Object.assign({}, d, {
          medicalEvents: medicalEvents ? [medicalEvents] : [],
        })
      })
    },
    documentRequests() {
      return this.docRequestsWithMedEventTypes.map(documentRequest => ({
        text: this.formatDocumentRequestName(documentRequest),
        value: { ...documentRequest },
      }))
    },
    documentTypeErrors() {
      const errors = []
      if (!this.$v.form.documentType.$dirty) return errors
      if (!this.$v.form.documentType.required) errors.push('Document type is required')
      return errors
    },
    commentErrors() {
      const errors = []
      if (!this.$v.form.comment.$dirty) return errors
      if (!this.$v.form.comment.required) errors.push('Comment is required')
      return errors
    },
    leadErrors() {
      const errors = []
      if (!this.$v.leadId.$dirty) return errors
      if (!this.$v.leadId.required) errors.push('Lead is required')
      return errors
    },
    documentRequestErrors() {
      const errors = []
      if (!this.$v.form.documentRequest.$dirty) return errors
      if (!this.$v.form.documentRequest.required)
        errors.push('Document request or medical event(s) required')
      return errors
    },
    medicalEventsErrors() {
      const errors = []
      if (!this.$v.form.documentRequest.$dirty) return errors
      if (!this.$v.form.documentRequest.required)
        errors.push('Document request or medical event(s) required')
      return errors
    },
    documentRequestStatusErrors() {
      const errors = []
      if (!this.$v.form.documentRequestStatus.$dirty) return errors
      if (!this.$v.form.documentRequestStatus.required)
        errors.push('Document request status is required')
      return errors
    },
    documentRequestRejectedReasonErrors() {
      const errors = []
      if (!this.$v.form.documentRequestRejectedReason.$dirty) return errors
      if (!this.$v.form.documentRequestRejectedReason.required)
        errors.push('Document request rejected reason is required')
      return errors
    },
    documentRequestClosedReasonErrors() {
      const errors = []
      if (!this.$v.form.documentRequestClosedReason.$dirty) return errors
      if (!this.$v.form.documentRequestClosedReason.required)
        errors.push('Document request closed reason is required')
      return errors
    },
    invoiceMustBePdfError() {
      const errors = []
      if (!this.$v.file.invoiceMustBePdf) errors.push('Invoice must be a pdf')
      return errors
    },
    invoiceMaxSizeError() {
      const errors = []
      if (!this.$v.file.invoiceMaxSize) errors.push('Invoice file size must be less than 1.5mb')
      return errors
    },
    invoiceMaxPagesError() {
      const errors = []
      if (!this.$v.file.invoiceMaxPages) errors.push('Invoice file must have 6 or less pages')
      return errors
    },
    fileNameError() {
      const errors = []
      if (this.file) {
        if (!this.$v.file?.fileNameExtension)
          errors.push(`File name must contain the extension '.${this.file?.fileExtension}'`)
        return errors
      }
      if (!this.$v.document?.fileNameExtension)
        errors.push(
          `File name must contain the extension '.${mime.extension(this.document?.fileType)}'`,
        )
      return errors
    },
    additionalInvoiceGeneratedFileName() {
      return this.getGeneratedFileName('Invoice')
    },
    showDocRequest() {
      return (
        this.$store.getters.documentTypesConfig?.[this.form?.documentType]?.showDocumentRequest &&
        this.leadId
      )
    },
  },

  watch: {
    'form.documentRequest'(val, prev) {
      if (val?.id === prev?.id) return
      this.form.documentRequestStatus = val.status
      this.generateFileName()
      if (val?.status === 'rejected' && val?.rejectedReason) {
        this.form.documentRequestRejectedReason = val.rejectedReason
      }
      if (val?.status === 'closed' && val?.closedReason) {
        this.form.documentRequestClosedReason = val.closedReason
      }
    },
    'form.documentType'() {
      this.generateFileName()
    },
    'form.documentSubType'() {
      this.generateFileName()
    },
    'form.medicalEvents'() {
      this.generateFileName()
    },
    leadId() {
      this.refetchDocumentRequests()
      this.refetchMedicalEvents()
      this.generateFileName()
    },
  },

  async mounted() {
    if (this.preselectedLeadId) {
      this.selectedLead = await this.getLeadById({ id: this.preselectedLeadId, join: ['claimant'] })
    }

    if (
      this.file?.fileType.toLowerCase() === 'application/pdf' ||
      this.file?.fileExtension.toLowerCase() === 'pdf'
    ) {
      let pdfDoc
      let buffer = ''
      try {
        buffer = await readFile(this.file.file)
        pdfDoc = await PDFDocument.load(buffer, { ignoreEncryption: true })
        if (pdfDoc.isEncrypted) {
          this.isEncrypted = true
        }
      } catch (err) {
        this.showError = true
        this.$sentry.captureException(err)
        // temp debugging
        try {
          this.$sentry.captureMessage(
            `additionalInvoice: ${this.additionalInvoice}`,
            JSON.stringify(pdfDoc.context),
          )
          this.$sentry.captureMessage(`additionalInvoice: ${this.additionalInvoice}`, buffer)
        } catch (e) {}
      }
    } else if (this.document) {
      this.isEncrypted = !!this.document.isEncrypted

      if (this.document.type === 'Unredacted Medical Records')
        this.form.documentType = this.document.type
    }
  },

  methods: {
    clear() {
      this.form.documentRequests = []
      this.form.medicalEvents = []
    },
    ...mapActions('resources/leads', { getLeadById: 'getById' }),
    ...mapActions('resources/document-requests', {
      patchDocumentRequest: 'patch',
    }),
    ...mapActions('resources/documents', {
      postDocument: 'post',
      getDocument: 'getById',
      patchDocument: 'patch',
      delete: 'delete',
    }),
    ...mapActions('resources/document-request-invoices', {
      postInvoice: 'post',
      getInvoice: 'get',
    }),
    ...mapActions('resources/activity', {
      postActivity: 'post',
    }),

    async viewDocument() {
      const document = await this.getDocument({
        id: this.existingInvoiceDocumentId,
      })

      window.open(
        (await this.$axios.$get('v1/documents/signed-urls', { params: { key: document.key } })).url,
        '_blank',
      )
    },
    generateFileName() {
      this.form.customFileName = this.getGeneratedFileName(this.form.documentType)
      return this.form.customFileName
    },
    getGeneratedFileName(documentType) {
      const claimant = this.selectedLead?.claimant
      const documentSubType = this.form.documentSubType
      const dateStr = this.document?.createdAt ?? new Date()
      const ext =
        this.document?.fileName.substr(this.document.fileName.lastIndexOf('.') + 1) ??
        this.file?.file.name.substr(this.file.file.name.lastIndexOf('.') + 1)

      let facility
      if (this.form.documentRequest) {
        facility = this.form.documentRequest?.medicalFacility
      }
      if (this.form.medicalEvents?.length) {
        const medicalEvent = this.medicalEvents?.data.find(
          me => me.id === this.form.medicalEvents[0],
        )
        facility = medicalEvent?.medicalFacility
      }

      let name = ''
      if (claimant) name += `${claimant.lastName}, ${claimant.firstName}`
      if (facility) name += `${name.length > 0 ? '_' : ''}${facility.name}`
      if (documentType) name += `${name.length > 0 ? '_' : ''}${documentType}`
      if (documentSubType) name += `${name.length > 0 ? '_' : ''}${documentSubType}`
      if (dateStr) {
        const date = new Date(dateStr)
        const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`
        const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`
        const year = date.getFullYear()

        name += `${name.length > 0 ? '_' : ''}${month}${day}${year}`
      }
      if (ext) name += `.${ext}`

      return sanitize(name)
    },

    formatDocumentRequestName(request) {
      const fromDate = request.fromDate ? this.formatDate(request.fromDate) : 'Unknown'
      const toDate = request.toDate ? this.formatDate(request.toDate) : 'Present'
      const medEventTypeName = request.medicalEvents[0]?.medicalEventType?.name ?? ''

      return `${request.medicalFacility.name} (${fromDate} - ${toDate}) ${medEventTypeName}`.trim()
    },

    formatMedicalEventName(medicalEvent) {
      if (!medicalEvent.medicalEventType)
        return `${medicalEvent.type} - ${medicalEvent.medicalFacility.name} (${this.formatDate(
          medicalEvent.date,
        )})`

      return `${medicalEvent.medicalEventType.name} - ${
        medicalEvent.medicalFacility.name
      } (${this.formatDate(medicalEvent.date)})`
    },

    formatDate(date) {
      return moment.utc(date).format('MMM YYYY')
    },

    async submit() {
      if (this.$refs.invoiceFormRef) {
        this.$refs.invoiceFormRef.$v.$touch()
        if (this.$refs.invoiceFormRef.$v.$invalid) return
      }

      if (this.$refs.additionalInvoiceRef) {
        this.$refs.additionalInvoiceRef.$v.$touch()
        if (this.$refs.additionalInvoiceRef.$v.$invalid) return
      }

      this.$v.$touch()
      if (this.$v.$invalid) return

      this.loading = true
      try {
        if (this.form.documentType === 'Invoice' || this.additionalInvoice) {
          this.existingInvoiceDocumentId = null

          const invoiceId = this.form.invoiceId

          const existingInvoices = await this.getInvoice({
            search: {
              documentRequestId: this.form.documentRequest.id,
              amount: this.form.invoiceAmount,
              invoiceId,
            },
          })

          if (existingInvoices.data.length) {
            this.existingInvoiceDocumentId = existingInvoices.data[0].documentId
            this.loading = false
            return
          }
        }
      } catch (e) {
        this.$sentry.captureException(e)
        return
      }
      try {
        let document
        // get signed url for S3 upload

        let type = this.form.documentType
        if (this.selectedLead.matterType === '3M EarPlugs' && type === 'Medical Records')
          type = 'Unredacted Medical Records'

        if (this.file) {
          const result = await this.$axios.$post('v1/documents/signed-urls', {
            contentType: this.file.fileType,
            filePath: this.file.file.name,
          })

          await axios.put(result.url, this.file.file, {
            headers: {
              'Content-Type': this.file.fileType,
            },
          })
          document = await this.postDocument({
            data: {
              leadId: this.leadId,
              documentRequestId: this.form?.documentRequest?.id,
              medicalEvents: this.form.medicalEvents.map(id => ({ id })),
              fileName: this.form.customFileName ? this.form.customFileName : this.file.file.name,
              fileType: this.file.fileType,
              key: result.key,
              type,
              subType: this.form.documentSubType,
              uploadedAt: new Date().toISOString(),
              uploadedByUserId: this.$auth.user.id,
              template: this.form.documentType === 'Medical Release' ? 'manual' : null,
              isEncrypted: this.isEncrypted,
            },
          })
        } else if (this.document) {
          // post document & update document request
          document = await this.patchDocument({
            data: {
              id: this.document.id,
              fileName: this.form.customFileName
                ? this.form.customFileName
                : this.document.fileName,
              leadId: this.leadId,
              documentRequestId: this.form?.documentRequest?.id,
              medicalEvents: this.form.medicalEvents.map(id => ({ id })),
              type,
              subType: this.form.documentSubType,
              uploadedAt: new Date().toISOString(),
              uploadedByUserId: this.$auth.user.id,
              template: this.form.documentType === 'Medical Release' ? 'manual' : null,
            },
          })
        }

        if (
          this.form?.documentRequest?.id &&
          this.form.documentType !== 'Claimant Correspondence'
        ) {
          await this.patchDocumentRequest({
            data: {
              id: this.form.documentRequest.id,
              status: this.form.documentRequestStatus,
              rejectedReason: this.form.documentRequestRejectedReason,
              closedReason: this.form.documentRequestClosedReason,
            },
          })
        }
        if (this.additionalInvoice) {
          try {
            await this.$axios.$post('v1/documents/split-invoice', {
              documentId: document.id,
              newFileName: this.additionalInvoiceGeneratedFileName,
              documentRequestId: this.form?.documentRequest?.id,
              startPage: this.form.startPage,
              endPage: this.form.endPage,
              invoiceData: {
                invoiceId: this.form.invoiceId,
                invoiceDate: this.form.invoiceDate,
                dueDate: this.form.invoiceDueDate,
                amount: this.form.invoiceAmount,
                paymentDate: this.form.invoicePaymentDate,
                paymentMethod: this.form.invoicePaymentMethod,
                paymentReferenceNumber: this.form.invoicePaymentReferenceNumber,
                payeeName: this.form.payeeName,
                payeeAddress1: this.form.payeeAddress1,
                payeeAddress2: this.form.payeeAddress2,
                payeeCity: this.form.payeeCity,
                payeeState: this.form.payeeState,
                payeeZip: this.form.payeeZip,
              },
            })
          } catch (e) {
            this.$sentry.captureException(e)
          }
        }

        if (this.form.pageSubSet) {
          try {
            await this.$axios.$post('v1/documents/trim', {
              documentId: document.id,
              startPage: this.form.startPage,
              endPage: this.form.endPage,
            })
          } catch (e) {
            this.$sentry.captureException(e)
          }
        }

        if (this.form.documentType === 'Invoice') {
          try {
            await this.postInvoice({
              data: {
                documentId: document.id,
                documentRequestId: this.form?.documentRequest?.id,
                invoiceId: this.form.invoiceId,
                invoiceDate: this.form.invoiceDate,
                dueDate: this.form.invoiceDueDate,
                amount: this.form.invoiceAmount,
                paymentDate: this.form.invoicePaymentDate,
                paymentMethod: this.form.invoicePaymentMethod,
                paymentReferenceNumber: this.form.invoicePaymentReferenceNumber,
                payeeName: this.form.payeeName,
                payeeAddress1: this.form.payeeAddress1,
                payeeAddress2: this.form.payeeAddress2,
                payeeCity: this.form.payeeCity,
                payeeState: this.form.payeeState,
                payeeZip: this.form.payeeZip,
              },
            })
          } catch (e) {
            this.$sentry.captureException(e)
          }
        }

        if (this.form?.comment?.trim()) {
          const data = {
            user: this.$auth.user,
            type: 'comment',
            detail: this.form.comment.trim(),
          }

          if (this.form?.documentRequest?.id) {
            data.secondaryRelationship = 'document_request'
            data.secondaryRelationshipId = this.form.documentRequest.id
          }

          await this.postActivity({
            data,
            options: { parentRoute: `leads/${this.leadId}`, join: ['user'] },
          })
        }

        if (this.isEncrypted && this.password) {
          await this.$axios.$post(`v1/documents/${document.id}/unlock`, {
            password: this.password,
          })
        }

        this.$emit('documentSaved')
      } catch (error) {
        this.$sentry.captureException(error)
        console.error(error)
      } finally {
        this.loading = false
        this.complete = true
      }
    },

    async downloadDocument() {
      window.open(
        (await this.$axios.$get('v1/documents/signed-urls', { params: { key: this.document.key } }))
          .url,
        '_blank',
      )
    },

    async deleteDocument() {
      if (window.confirm('Are you sure you want to delete this document?')) {
        await this.delete({ data: this.document })
        this.$emit('deleted')
      }
    },
  },
}

async function getPdfPageCount(file) {
  try {
    const arrayBuffer = await readFile(file)

    const pdf = await PDFDocument.load(arrayBuffer)

    return pdf.getPages().length
  } catch (error) {
    // ignore
  }
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()

      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)

      reader.readAsArrayBuffer(file)
    } catch (error) {
      // ignore
    }
  })
}
