import Pusher from 'pusher-js'
import { PusherMock } from 'pusher-js-mock'

export default async ({ app, $config, $auth }, inject) => {
  // @ts-ignore
  if (!$config.pusherKey && typeof window?.Cypress === 'undefined') return
  if ($config.appEnv !== 'production') Pusher.logToConsole = false

  if ($auth.$storage.state.loggedIn) {
    const pusher = await registerChannelSubscriptions({ app, $config, $auth })
    inject('pusher', pusher)
  } else {
    $auth.$storage.watchState('loggedIn', async () => {
      const pusher = await registerChannelSubscriptions({ app, $config, $auth })
      inject('pusher', pusher)
    })
  }
}

function registerChannelSubscriptions({ app, $config, $auth }) {
  let pusher
  // @ts-ignore
  if (typeof window?.Cypress !== 'undefined') {
    pusher = new PusherMock()
  } else {
    pusher = new Pusher($config.pusherKey, {
      authEndpoint: `${$config.apiUrl}/v1/pusher/auth`,
      auth: {
        headers: {
          Authorization: $auth.strategy.token.get(),
        },
      },
      cluster: 'us2',
    })
  }

  // -------------------------------------------
  // TASKS CHANNEL
  // -------------------------------------------

  const taskChannel = pusher.subscribe('private-queue-lead-tasks')

  const events = [
    'task-assignee-changed',
    'task-snooze-changed',
    'task-status-changed',
    'task-blocked-changed',
  ]

  events.forEach(event => {
    taskChannel.bind(event, task => app.store.dispatch('task-events/taskUpdate', task))
  })

  // -------------------------------------------
  // BLOCKERS CHANNEL
  // -------------------------------------------

  const blockerChannel = pusher.subscribe('private-task-blockers')
  blockerChannel.bind('task-blocker-changed', blocker => {
    app.store.dispatch('task-events/taskBlockerUpdate', blocker)
  })

  // -------------------------------------------
  // USER CHANNEL
  // -------------------------------------------

  const user = $auth.$state.user
  const userChannel = pusher.subscribe(`private-user-${user.id}`)
  userChannel.bind('task-assignee-changed', task => {
    app.store.dispatch('user-tasks/setTaskNotification', {
      notification:
        String(user.id) === String(task.assignedToId)
          ? 'newTaskNotification'
          : 'unassignedTaskNotification',
      value: true,
    })

    app.store.dispatch('user-tasks/refresh', {})
  })

  userChannel.bind('task-assigned', _ => {
    app.store.dispatch('user-tasks/setTaskNotification', {
      notification: 'newTaskNotification',
      value: true,
    })

    app.store.dispatch('user-tasks/refresh', {})
  })

  userChannel.bind('task-unassigned', _ => {
    app.store.dispatch('user-tasks/setTaskNotification', {
      notification: 'unassignedTaskNotification',
      value: true,
    })

    app.store.dispatch('user-tasks/refresh', {})
  })

  userChannel.bind('blocker-assignee-changed', task => {
    app.store.dispatch('user-blockers/setBlockerNotification', {
      notification:
        String(user.id) === String(task.assignedToId)
          ? 'newBlockerNotification'
          : 'unassignedBlockerNotification',
      value: true,
    })

    app.store.dispatch('user-blockers/refresh')
  })

  userChannel.bind('blocker-assigned', _ => {
    app.store.dispatch('user-blockers/setBlockerNotification', {
      notification: 'newBlockerNotification',
      value: true,
    })

    app.store.dispatch('user-blockers/refresh')
  })

  userChannel.bind('blocker-unassigned', _ => {
    app.store.dispatch('user-blockers/setBlockerNotification', {
      notification: 'unassignedBlockerNotification',
      value: true,
    })

    app.store.dispatch('user-blockers/refresh')
  })

  // -------------------------------------------
  // CONVERSATIONS CHANNEL
  // -------------------------------------------

  const conversationsChannel = pusher.subscribe('private-conversations')
  conversationsChannel.bind('message-received', id => {
    app.store.dispatch('conversations/getConversation', id)
  })

  return pusher
}
