






























import { format as formatPhoneNumber } from 'phone-fns'
import Vue from 'vue'

import { NewConversation } from '~/types/NewConversation'

export default Vue.extend({
  name: 'NewChat',

  computed: {
    newConversation(): NewConversation {
      return this.$store.getters['conversations/newConversation']
    },
    to: {
      get() {
        return this.$store.getters['conversations/newConversation'].to
      },
      async set(to) {
        await this.$store.dispatch('conversations/setNewConversationTo', to)
      },
    },
    toFormatted(): String {
      if (this.newConversation?.to?.length === 10 && !this.newConversation?.to?.includes('@'))
        return `+1${this.newConversation.to}`

      return ''
    },
  },

  methods: {
    async findOrCreateConveration() {
      await this.$store.dispatch('conversations/createConversation')
    },

    formatPhoneNumber(string) {
      return formatPhoneNumber('+N-NNN-NNN-NNNN', string)
    },
  },
})
