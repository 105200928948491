import { render, staticRenderFns } from "./UserBlockers.vue?vue&type=template&id=8000104e&"
import script from "./UserBlockers.vue?vue&type=script&lang=js&"
export * from "./UserBlockers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AgoDate: require('/home/node/components/AgoDate.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VList,VListItem,VListItemContent,VSlideXTransition})
