import isEmail from 'isemail'
import Vue from 'vue'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

import { Conversation, ConversationChannel } from '~/types/Conversation'
import { Message } from '~/types/Message'
import { NewConversation } from '~/types/NewConversation'

type OpenConversationDisplay = {
  id: string
  minimized: boolean
  expanded: boolean
}

const previewJoinedRelations = ['assignedToUser', 'lead', 'lead.claimant', 'lead.lawFirm']

const joinedRelations = [
  'messages',
  'messages.attachments',
  'messages.attachments.document',
  'assignedToUser',
  'dispositionedByUser',
  'lead',
  'lead.claimant',
  'lead.lawFirm',
]

interface State {
  searchFields: string[]
  raw: Conversation[]
  openConversations: string[]
  openConversationDisplays: OpenConversationDisplay[]
  search: string
  showResolved: boolean
  showAssignedToOthers: boolean
  showAssignedToMe: boolean
  showUnassigned: boolean
  showMissingLead: boolean
  newConversation: NewConversation
  selectedCampaigns: any[]
  loading: boolean
}

export const state = (): State => ({
  searchFields: ['lead_claimant.firstName', 'lead_claimant.lastName'],
  raw: [],
  openConversations: [],
  openConversationDisplays: [],
  search: '',
  showResolved: false,
  showAssignedToOthers: false,
  showMissingLead: false,
  showAssignedToMe: false,
  showUnassigned: false,
  newConversation: { to: '' },
  selectedCampaigns: [],
  loading: true,
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  raw: state => state.raw,
  loading: state => state.loading,
  search: state => state.search,
  searchFilter: state => {
    const searchFilter = state.search.split(' ').map(word => ({
      $or: state.searchFields
        .map(field => {
          if (field === 'id' || field.endsWith('.id')) {
            const isInteger = /^\d+$/g.test(word)
            if (!isInteger) return null
            return { [field]: { $eq: Number(word) } }
          }
          return { [field]: { $contL: word } }
        })
        .filter(f => !!f),
    }))

    return searchFilter
  },
  showResolved: state => state.showResolved,
  selectedCampaigns: state => state.selectedCampaigns,
  savedFilterKey: _ => 'conversations-campaigns-filter-v1',
  showAssignedToOthers: state => state.showAssignedToOthers,
  showMissingLead: state => state.showMissingLead,
  openConversations: state => state.openConversations,
  showAssignedToMe: state => state.showAssignedToMe,
  showUnassigned: state => state.showUnassigned,
  openConversationDisplays: state => state.openConversationDisplays,
  all: state => {
    const conversations = {}
    for (const conversation of state.raw) {
      conversations[conversation.id] = {
        ...conversation,
        messages: conversation.messages
          ? [...conversation.messages].sort((a, b) => a.id - b.id)
          : [],
      }
    }
    return conversations
  },
  newConversation: state => state.newConversation,
  newConversationChannel: state => {
    if (state.newConversation.to?.includes('@')) return ConversationChannel.EMAIL
    return ConversationChannel.SMS
  },
  newConversationToFormatted: state => {
    return `+1${state.newConversation.to}`
  },
  needsAction: (_state, getters) => {
    const result: Conversation[] = []

    Object.values(getters.all as Conversation[]).forEach(conversation => {
      if (!conversation.dispositionedAt) result.push(conversation)
    })

    return result
  },
}

export const mutations: MutationTree<RootState> = {
  search(state, search) {
    state.search = search
  },
  setConversations(state, conversations) {
    state.raw = conversations
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  toggleShowResolved(state) {
    state.showResolved = !state.showResolved
  },
  toggleShowAssignedToOthers(state) {
    state.showAssignedToOthers = !state.showAssignedToOthers
  },
  toggleShowMissingLead(state) {
    state.showMissingLead = !state.showMissingLead
  },

  toggleShowAssignedToMe(state) {
    state.showAssignedToMe = !state.showAssignedToMe
  },
  toggleShowUnassigned(state) {
    state.showUnassigned = !state.showUnassigned
  },
  set(state, conversation) {
    const index = state.raw.findIndex(c => c.id === conversation.id)

    if (index === -1) {
      state.raw.push(conversation)
    } else {
      Vue.set(state.raw, index, conversation)
    }
  },
  closeConversation(state, id) {
    const index = state.openConversations.indexOf(id)

    if (index === -1) return

    state.openConversations.splice(index, 1)
    state.openConversationDisplays.splice(index, 1)
  },
  openConversationDisplays(state, value) {
    state.openConversationDisplays.push(value)
  },
  openConversation(state, id) {
    state.openConversations.push(id)
    state.openConversationDisplays.push({ id, minimized: false, expanded: false })
  },
  setNewConversationLeadId(state, leadId) {
    state.newConversation.leadId = leadId
  },
  setNewConversationTo(state, to) {
    state.newConversation.to = to
  },
  minimizeConversation(state, { id, value }) {
    const index = state.openConversations.indexOf(id)

    if (index === -1) return

    state.openConversationDisplays[index].minimized = value
  },
  expandConversation(state, { id, value }) {
    const index = state.openConversations.indexOf(id)

    if (index === -1) return

    state.openConversationDisplays[index].expanded = value
  },
  selectCampaigns(state, selectedCampaigns) {
    state.selectedCampaigns = selectedCampaigns
  },
}

export const actions: ActionTree<RootState, RootState> = {
  setNewConversationLeadId({ commit }, leadId: string) {
    if (leadId) commit('setNewConversationLeadId', leadId)
  },

  setNewConversationTo({ commit }, to: string) {
    if (to) commit('setNewConversationTo', to)
  },

  minimizeConversation({ commit }, payload: { id: string; value: boolean }) {
    if (payload) {
      commit('minimizeConversation', payload)
      if (payload.value !== false) {
        commit('expandConversation', { id: payload.id, value: false })
      }
    }
  },

  expandConversation({ commit }, payload: { id: string; value: string }) {
    if (payload) {
      commit('expandConversation', payload)
      commit('minimizeConversation', { id: payload.id, value: false })
    }
  },

  async createConversation({ commit, dispatch, getters, rootState }) {
    const channel = getters.newConversationChannel

    if (channel === ConversationChannel.SMS && getters.newConversationToFormatted.length !== 12)
      return

    if (channel === ConversationChannel.EMAIL && !isEmail.validate(getters.newConversation.to))
      return

    const claimantContact =
      getters.newConversationChannel === ConversationChannel.SMS
        ? getters.newConversationToFormatted
        : getters.newConversation.to

    const torticityContact =
      getters.newConversationChannel === ConversationChannel.SMS
        ? '+15614487828'
        : 'support@mail.legalservicesupport.com'

    const foundConversation: Conversation = await this.$axios.$get('v1/conversations/find', {
      params: {
        participants: [torticityContact, claimantContact],
        leadId: getters.newConversation.leadId,
        disposition: null,
        channel: getters.newConversationChannel,
      },
    })

    if (foundConversation) {
      const conversationsWithJoinedFields = await this.$axios.$get(
        `v1/conversations/${foundConversation.id}`,
        {
          params: {
            join: joinedRelations,
          },
        },
      )
      commit('set', conversationsWithJoinedFields)
      return await dispatch('replaceConversation', {
        oldId: 'new',
        newId: conversationsWithJoinedFields.id,
      })
    }

    const createdConversation = await this.$axios.$post(
      'v1/conversations',
      {
        participants: [torticityContact, claimantContact],
        leadId: getters.newConversation.leadId,
        channel: getters.newConversationChannel,
        // @ts-ignore
        assignedToUserId: rootState.auth.user.id,
      },

      {
        params: {
          join: joinedRelations,
        },
      },
    )

    commit('set', createdConversation)

    await dispatch('replaceConversation', { oldId: 'new', newId: createdConversation.id })
  },

  async openConversation({ commit, dispatch, getters }, { id }) {
    if (getters.openConversations.includes(id)) return

    const maxConversations = Math.min(2, Math.floor(window.innerWidth / 400))
    if (getters.openConversations.length === maxConversations)
      await dispatch('closeConversation', { id: getters.openConversations[0] })

    commit('openConversation', id)
  },

  closeConversation({ commit }, { id }) {
    commit('closeConversation', id)
  },

  replaceConversation({ commit }, { oldId, newId }) {
    commit('closeConversation', oldId)
    commit('openConversation', newId)
  },

  async search({ commit, dispatch }, search) {
    // convert search to typeorm crud query params
    commit('search', search)

    await dispatch('getConversations')
  },

  toggleShowResolved({ commit, dispatch }) {
    commit('toggleShowResolved')
    dispatch('getConversations')
  },

  toggleShowAssignedToOthers({ commit, dispatch }) {
    commit('toggleShowAssignedToOthers')
    dispatch('getConversations')
  },

  toggleShowAssignedToMe({ commit, dispatch }) {
    commit('toggleShowAssignedToMe')
    dispatch('getConversations')
  },

  toggleShowUnassigned({ commit, dispatch }) {
    commit('toggleShowUnassigned')
    dispatch('getConversations')
  },

  async toggleShowMissingLead({ commit, dispatch }) {
    commit('toggleShowMissingLead')
    await dispatch('selectCampaigns', [])
    await dispatch('search', '')
    await dispatch('getConversations')
  },

  async sendMessage({ dispatch }, { to, from, message, conversation, attachments }) {
    let messageBody
    if (conversation.channel === ConversationChannel.SMS) messageBody = message
    if (conversation.channel === ConversationChannel.EMAIL) {
      messageBody = {
        subject:
          conversation.messages[conversation.messages.length - 1]?.message?.subject?.length > 0
            ? `Re: ${conversation.messages[conversation.messages.length - 1].message.subject}`
            : 'Regarding Your Claim',
        text: message,
      }
    }

    await this.$axios.$post('v1/messages', {
      to,
      from,
      message: messageBody,
      conversationId: conversation.id,
      attachments,
    })
    await dispatch('getConversation', conversation.id)
  },

  async selectCampaigns({ commit, dispatch, getters }, selectedCampaigns) {
    commit('selectCampaigns', selectedCampaigns)
    localStorage.setItem(getters.savedFilterKey, JSON.stringify(selectedCampaigns))
    await dispatch('getConversations')
  },

  // @ts-ignore
  async getConversations({ commit, getters }) {
    commit('setLoading', true)
    const campaignFilter = getters.selectedCampaigns.length
      ? getters.selectedCampaigns.map(({ lawFirmName, matterType }) => ({
          'lead.lawFirm.name': lawFirmName,
          'lead.matterType': matterType,
        }))
      : []

    let search = { $and: [] }

    if (campaignFilter.length) {
      search.$and.push({
        // @ts-ignore
        $or: campaignFilter,
      })
    }

    if (getters.search?.length) {
      // @ts-ignore
      search.$and.push(...getters.searchFilter)
    }

    const assignedFilter: object[] = []

    if (getters.showAssignedToMe) {
      assignedFilter.push({
        assignedToUserId: { $eq: this?.$auth?.user?.id },
      })
    }
    if (getters.showAssignedToOthers) {
      assignedFilter.push({
        assignedToUserId: { $ne: this?.$auth?.user?.id },
      })
    }
    if (getters.showUnassigned) {
      assignedFilter.push({
        assignedToUserId: { $isnull: true },
      })
    }

    if (getters.showAssignedToMe || getters.showAssignedToOthers || getters.showUnassigned)
      search.$and.push({
        // @ts-ignore
        $or: assignedFilter,
      })

    if (!getters.showResolved) {
      search.$and.push({
        // @ts-ignore
        disposition: { $isnull: true },
      })
    }

    if (getters.showMissingLead) {
      search.$and.push({
        // @ts-ignore
        leadId: { $isnull: true },
      })
    }

    const openConversations = getters.openConversations.filter(id => id !== 'new')
    if (openConversations.length) {
      // @ts-ignore
      search = {
        // @ts-ignore
        $or: [search, { id: { $in: openConversations } }],
      }
    }

    const conversations: Conversation[] = await this.$axios.$get('v1/conversations', {
      params: {
        limit: 1000,
        join: previewJoinedRelations,
        s: search,
      },
    })

    commit('setConversations', conversations)
    commit('setLoading', false)
  },

  async getConversation({ commit }, id) {
    const conversation: Conversation = await this.$axios.$get(`v1/conversations/${id}`, {
      params: {
        join: joinedRelations,
      },
    })
    commit('set', conversation)
  },

  async markMessageAsRead({ dispatch }, id) {
    const updatedMessage: Message = await this.$axios.$patch(`v1/messages/${id}`, {
      seenAt: new Date(),
    })

    dispatch('getConversation', updatedMessage.conversationId)
  },
}
