export default function({ app }) {
  app.router.beforeEach((to, from, next) => {
    if (from.query.teamId && !to.query.teamId) {
      if (to.path === from.path) return // This is a no-no via the documentation, but a bug in routing to identical routes strips query params, and this prevents that

      if (to.params.leadId === from.params.leadId)
        next({ path: to.path, query: { teamId: from.query.teamId } })
    }

    next()
  })
}
