export default function({ route, redirect }) {
  const redirects = {
    'queues-id-documents-documentId': `/documents/${route.params.documentId}`,
    'queues-id-documents-documentId-tasks': `/documents/${route.params.documentId}/tasks`,
    'queues-id-documents-documentId-tasks-taskId': `/documents/${route.params.documentId}/tasks/${route.params.taskId}`,
    'queues-id-leads-leadId': `/leads/${route.params.leadId}`,
    'queues-id-leads-leadId-tasks': `/leads/${route.params.leadId}/tasks`,
    'queues-id-leads-leadId-tasks-taskId': `/leads/${route.params.leadId}/tasks/${route.params.taskId}`,
    'queues-id-leads-leadId-activity': `/leads/${route.params.leadId}/activity`,
    'queues-id-leads-leadId-documents': `/leads/${route.params.leadId}/documents`,
    'queues-id-leads-leadId-outreach-requests': `/leads/${route.params.leadId}/outreach-requests`,
    'queues-id-leads-leadId-outreach-requests-outreachRequestId': `/leads/${route.params.leadId}/outreach-requests/${route.params.outreachRequestId}`,
  }

  if (!Object.keys(redirects).includes(route.name)) return

  let redirectUrl = redirects[route.name]

  if (route.params.id && route.params.id !== 'na') redirectUrl += `?teamId=${route.params.id}`

  return redirect(redirectUrl)
}
