//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash/debounce'
import moment from 'moment'

export default {
  inheritAttrs: false,
  props: {
    testPrefix: {
      type: String,
      required: false,
      default: '',
    },
    version: {
      type: String,
      required: false,
      default: 'v1',
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: String,
      required: true,
    },
    searchFields: {
      type: Array,
      required: false,
      default: null,
    },
    searchBackgroundColor: {
      type: String,
      required: false,
      default: undefined,
    },
    searchDense: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchLabel: {
      type: String,
      required: false,
      default: '',
    },

    searchOutlined: {
      type: Boolean,
      required: false,
      default: false,
    },

    parentRoute: {
      type: String,
      required: false,
      default: '',
    },

    copyable: {
      type: Boolean,
      required: false,
      default: false,
    },
    creatable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewable: {
      type: Boolean,
      required: false,
      default: false,
    },
    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClickable: {
      type: Boolean,
      required: false,
      default: false,
    },

    join: {
      type: Array,
      required: false,
      default: null,
    },
    filter: {
      type: [Array, Object],
      required: false,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      required: false,
      default: () => [10],
    },
    sort: {
      type: Array,
      required: false,
      default: () => [],
    },
    saveSearchFilters: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectedItemId: {
      type: String,
      required: false,
      default: '',
    },
    select: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    options: {},
    search: '',
  }),
  computed: {
    searchPlaceholder() {
      let fields
      if (this.searchLabel !== '') {
        fields = this.searchLabel
      } else {
        fields = this.searchFields
          .map(field => {
            if (!field.includes('.'))
              return field
                .split(/(?=[A-Z])/)
                .join(' ')
                .toLowerCase()
            return field
              .split('.')[1]
              .split(/(?=[A-Z])/)
              .join(' ')
              .toLowerCase()
          })
          .join(', ')
      }
      return `Search by ${fields}`
    },
    optionsKey() {
      return `${this.resource}.${this.$route.fullPath}.datatable.options.v2`
    },
    searchKey() {
      return `${this.resource}.${this.$route.fullPath}.datatable.search.v2`
    },
    items() {
      return this.$store.getters[`resources/${this.resource}/all`]
    },
    selectedItem() {
      return this.$store.getters[`resources/${this.resource}/all`]?.find(
        i => i !== undefined && i.id === this.selectedItemId,
      )
    },

    searchObject() {
      if (!this.search || !this.searchFields || !this.search?.trim()?.length) {
        if (Array.isArray(this.filter)) return { $and: this.filter }

        return this.filter
      }

      const searchFilter = {
        $and: this.search.split(' ').map(word => ({
          $or: this.searchFields
            .map(field => {
              if (field === 'id' || field.endsWith('.id')) {
                const isInteger = /^\d+$/g.test(word)
                if (!isInteger) return null
                return { [field]: { $eq: Number(word) } }
              }
              if (field.endsWith('.dateOfBirth')) {
                const date = moment(word, ['YYYY-MM-DD', 'MM-DD-YYYY', 'MM/DD/YYYY'], true)
                if (!date.isValid()) return null
                return { [field]: { $eq: date.format('YYYY-MM-DD') } }
              }
              return { [field]: { $contL: word } }
            })
            .filter(f => !!f),
        })),
      }

      if (!this.filter) return searchFilter

      if (!Array.isArray(this.filter)) {
        return {
          $and: [this.filter, ...searchFilter.$and],
        }
      }

      return {
        $and: [...this.filter, ...searchFilter.$and],
      }
    },

    searchAttrs() {
      return {
        value: this.search,
        'data-testid': 'search-input',
        appendIcon: 'mdi-magnify',
        label: this.searchPlaceholder,
        backgroundColor: this.searchBackgroundColor,
        clearable: true,
        outlined: this.searchOutlined,
        dense: this.searchDense,
        singleLine: true,
        hideDetails: true,
        class: { 'pa-0': true, 'ma-0': true },
      }
    },

    searchListeners() {
      return {
        input: val => {
          this.search = val
        },
      }
    },
  },
  watch: {
    async parentRoute() {
      await this.getDataFromApi()
    },

    options: {
      async handler() {
        await this.getDataFromApi()
        if (this.saveSearchFilters)
          localStorage.setItem(this.optionsKey, JSON.stringify(this.options))
      },
      deep: true,
    },
    search: {
      handler() {
        if (this.saveSearchFilters)
          localStorage.setItem(this.searchKey, JSON.stringify(this.search))
      },
    },
    filter: {
      handler: debounce(async function() {
        if (this.resetPagination()) return
        await this.getDataFromApi()
      }, 250),
      deep: true,
    },
    searchObject: {
      handler: debounce(async function() {
        if (this.resetPagination()) return
        await this.getDataFromApi()
      }, 250),
      deep: true,
    },
  },

  mounted() {
    if (this.saveSearchFilters) {
      this.restoreOptions()
      this.restoreSearch()
    }
  },

  created() {
    if (this.sort) {
      this.options.sortBy = this.sort.map(sort => sort.field)
      this.options.sortDesc = this.sort.map(sort => sort.order === 'DESC')
    }
    if (this.itemsPerPage) this.options.itemsPerPage = this.itemsPerPage
  },

  methods: {
    resetPagination() {
      const isOnFirstPage = this.options.page === 1

      if (!isOnFirstPage) {
        this.options.page = 1
        return true
      }

      return false
    },
    restoreOptions() {
      try {
        const savedOptions = JSON.parse(localStorage.getItem(this.optionsKey))
        if (savedOptions) this.options = savedOptions
      } catch (err) {
        console.error(err)
      }
    },
    restoreSearch() {
      try {
        const savedSearch = JSON.parse(localStorage.getItem(this.searchKey))
        if (savedSearch) this.search = savedSearch
      } catch (err) {
        console.error(err)
      }
    },

    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const dataTableSort = sortBy.map((field, i) => ({
        field,
        order: sortDesc[i] ? 'DESC' : 'ASC',
      }))

      this.loading = true

      const join = this.join?.map(join => {
        if (typeof join === 'string') {
          return { field: join }
        }
        return join
      })

      this.$emit('input', [])

      await this.$store.dispatch(`resources/${this.resource}/get`, {
        select: this.select,
        sort: dataTableSort,
        page,
        itemsPerPage,
        parentRoute: this.parentRoute,
        search: this.searchObject,
        join,
        version: this.version,
        controller: this.controller,
      })

      this.loading = false
    },
  },
}
