/*
 *
 *  each team db entity contains a "filter"
 *  which is a jsonb column used to manage the configuration of the team
 *
 * Configuration
 *  filter -> typeorm query to filter table to desired state
 *  tasks -> included tasks to display (no selected tasks displays all)
 *  campaigns -> included tasks to campaigns (no selected campaigns displays all)
 */

type TaskOption = {
  value: 'medical events' | 'document request creation'
  text: 'Medical Events' | 'Document Request Creation'
}

export const taskOptions: TaskOption[] = [
  { value: 'medical events', text: 'Medical Events' },
  { value: 'document request creation', text: 'Document Request Creation' },
]

type ConfigurationState = {
  name: string
  filter: any
  tasks: any
  campaigns: any
}

export const state = (): ConfigurationState => ({
  filter: {},
  name: '',
  tasks: [],
  campaigns: [],
})

export const getters = {
  filter: state => state.filter,
  name: state => state.name,
  tasks: state => state.tasks,
  campaigns: state => state.campaigns,
}

export const mutations = {
  setFilter(state, filter) {
    state.filter = filter
  },

  setName(state, name) {
    state.name = name
  },

  setTasks(state, tasks) {
    state.tasks = tasks
  },

  setCampaigns(state, campaigns) {
    state.campaigns = campaigns
  },
}

export const actions = {
  async preSetConfiguration({ commit }, team) {
    await commit('setName', team?.name)
    commit('setFilter', team?.filter)

    if (team?.filter?.campaigns) commit('setCampaigns', team?.filter?.campaigns)
    else commit('setCampaigns', [])

    if (team?.filter?.tasks) commit('setTasks', team?.filter?.tasks)
    else commit('setTasks', [])
  },

  async setFilterTasks({ commit, dispatch, getters }, { id, tasks }) {
    const filter = []

    await dispatch(
      'resources/teams/patch',
      {
        data: {
          id,
          filter: {
            tasks,
            campaigns: getters.campaigns,
          },
        },
      },
      { root: true },
    )

    commit('setFilter', filter)
    commit('setTasks', tasks)
  },

  async setFilterCampaigns({ commit, dispatch, getters }, { id, campaigns }) {
    const filter = []

    await dispatch(
      'resources/teams/patch',
      {
        data: {
          id,
          filter: {
            tasks: getters.tasks,
            campaigns,
          },
        },
      },
      { root: true },
    )

    commit('setFilter', filter)
    commit('setCampaigns', campaigns)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
