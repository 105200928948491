










































































































































































































































































import moment from 'moment'
import { format as formatPhoneNumber } from 'phone-fns'
import Vue, { PropType } from 'vue'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

import { Conversation, ConversationChannel, ConversationDisposition } from '~/types/Conversation'
import { Assignment } from '~/types/conversation-items/Assignment'
import { Creation } from '~/types/conversation-items/Creation'
import { Disposition } from '~/types/conversation-items/Disposition'
import { MessageItem } from '~/types/conversation-items/MessageItem'
import { Document } from '~/types/Document'
import { Lead } from '~/types/Lead'
import { Message } from '~/types/Message'
import { User } from '~/types/User'

export default Vue.extend({
  name: 'Chat',

  props: {
    conversationId: {
      type: String as PropType<string>,
      required: true,
    },
  },

  validations: {
    disposition: { required },
  },

  data: () => ({
    conversationMinimized: false,
    conversationExpanded: false,
    selectedAttachments: [] as Document[],
    attachmentDialog: false,
    message: '',
    submitting: false,
    sendingAttachments: false,
    disposeDialog: false,
    dialog: false,
    userHasScrolledUp: false,
    disposeOptions: [
      { text: 'Scheduled Call Back', value: 'scheduled_call_back' },
      { text: 'Not Interested', value: 'not_interested' },
      { text: 'Retainer Signed', value: 'retainer_signed' },
      { text: 'Wrong Number', value: 'wrong_number' },
      { text: 'Closed', value: 'closed' },
    ],
    doNotTextError: false,
    doNotEmailError: false,
    doNotEmail: false,
    conversationClosedError: false,
  }),

  computed: {
    channel(): ConversationChannel {
      return this?.conversation?.channel
    },
    doNotText: {
      get(): boolean {
        return !!this.conversation?.lead?.claimant?.doNotText
      },
      async set(value: boolean): Promise<void> {
        await this.$store.dispatch('resources/claimants/patch', {
          data: {
            id: this.conversation?.lead?.claimant?.id,
            doNotText: value,
          },
        })
      },
    },

    items(): (MessageItem | Creation | Disposition | Assignment)[] {
      const items: (MessageItem | Creation | Disposition | Assignment)[] = []
      const conversation = this.$store.getters['conversations/all']?.[this.conversationId]
      if (!conversation) return items

      items.push(
        ...this.messages.map(m => ({
          type: this.channel === ConversationChannel.SMS ? 'sms-message' : 'email-message',
          date: new Date(m.createdAt),
          data: m,
        })),
      )

      items.push({
        type: 'creation',
        date: new Date(conversation.createdAt),
      })

      if (conversation.assignedToUser) {
        items.push({
          type: 'assignment',
          date: new Date(conversation.assignedAt),
          user: conversation.assignedToUser,
        })
      }

      if (conversation.disposition) {
        items.push({
          type: 'disposition',
          disposition: conversation.disposition,
          date: new Date(conversation.dispositionedAt),
          user: conversation.dispositionedByUser,
        })
      }
      return items.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    },
    disposition: {
      get(): ConversationDisposition | undefined {
        return this.conversation?.disposition
      },
      async set(disposition: string): Promise<void> {
        await this.$store.dispatch('resources/conversations/patch', {
          data: {
            id: this.conversationId,
            disposition: disposition ?? null,
            dispositionedByUserId: disposition ? this.$auth.user.id : null,
            dispositionedAt: disposition ? new Date() : null,
          },
        })
        await this.$store.dispatch('conversations/getConversation', this.conversationId)
      },
    },

    assignee: {
      get(): User | undefined {
        return this?.conversation?.assignedToUser
      },
      async set(user: User): Promise<void> {
        await this.$store.dispatch('resources/conversations/patch', {
          data: {
            id: this.conversation?.id,
            assignedToUserId: user ? user.id : null,
            assignedAt: user ? new Date() : null,
          },
        })
      },
    },

    lead: {
      get(): Lead | undefined {
        return this?.conversation?.lead
      },
      async set(lead: Lead): Promise<void> {
        await this.$store.dispatch('resources/conversations/patch', {
          data: {
            id: this.conversation?.id,
            leadId: lead ? lead.id : null,
          },
        })
      },
    },

    messages(): Message[] {
      if (this.$store.getters['conversations/all']?.[this.conversationId]?.messages?.length)
        return [...this.$store.getters['conversations/all']?.[this.conversationId]?.messages]?.sort(
          (a, b) => a.id - b.id,
        )

      return []
    },

    conversation(): Conversation {
      return this.$store.getters['conversations/all']?.[this.conversationId]
    },

    contact(): string {
      return this.$store.getters['conversations/all']?.[this.conversationId].participants.find(
        p => p !== '+15614487828' && p !== 'support@mail.legalservicesupport.com',
      )
    },

    submitDisabled(): boolean {
      return this.submitting || !this.message.length
    },

    dispositionErrors() {
      const errors: string[] = []
      if (!this.$v.disposition.$dirty) return errors
      !this.$v.disposition.required && errors.push('Disposition is required')
      return errors
    },
    counter(): string {
      return this.channel === ConversationChannel.SMS ? '160' : ''
    },
  },

  updated() {
    if (!this.userHasScrolledUp)
      Vue.nextTick(() => {
        this.scrollToBottomOfChat()
      })
  },
  async beforeMount() {
    await this.$store.dispatch('conversations/getConversation', this.conversationId)
  },

  mounted() {
    this.scrollToBottomOfChat()
  },

  methods: {
    async minimizeConversation(value) {
      await this.$store.dispatch('conversations/minimizeConversation', {
        id: this.conversationId,
        value,
      })

      if (this.conversationExpanded) this.conversationExpanded = false

      this.conversationMinimized = value
    },

    async expandConversation(value) {
      await this.$store.dispatch('conversations/expandConversation', {
        id: this.conversationId,
        value,
      })
      if (this.conversationMinimized) this.conversationMinimized = false

      this.conversationExpanded = value
    },

    closeAttachmentDialog() {
      this.attachmentDialog = false
    },

    ...mapActions('resources/conversations', ['patch']),

    handleScroll(event) {
      this.userHasScrolledUp =
        event.target.scrollHeight - event.target.offsetHeight >= event.target.scrollTop + 30
    },

    async sendMessage() {
      if (!this.message.length) return
      if (this.conversation?.lead?.claimant?.doNotText) {
        this.doNotTextError = true
        return
      }

      if (this.conversation?.dispositionedAt) {
        this.conversationClosedError = true
        return
      }

      const from =
        this.conversation?.channel === ConversationChannel.SMS
          ? '+15614487828'
          : 'support@mail.legalservicesupport.com'

      this.submitting = true
      try {
        await this.$store.dispatch('conversations/sendMessage', {
          to: this.contact,
          from,
          message: this.message,
          conversation: this.conversation,
          attachments: this.selectedAttachments.map(attachment => ({
            documentId: attachment.id,
          })),
        })
      } finally {
        this.message = ''
        this.selectedAttachments = []
        this.submitting = false
      }
    },

    scrollToBottomOfChat() {
      const container: HTMLElement | null = this.$el?.querySelector?.('.chat-text')

      if (!container) return

      container.scrollTop = container.scrollHeight - container.offsetHeight
    },

    formatDate(date) {
      return moment(date).format('llll')
    },

    closeDialog() {
      this.dialog = false
    },

    openDisposeDialog() {
      this.disposeDialog = true
    },

    closeDisposeDialog() {
      this.disposeDialog = false
    },

    getConversationTitle(conversation: Conversation) {
      if (!conversation.lead || !conversation.lead.claimant || !conversation.lead.lawFirm) {
        if (conversation.channel === ConversationChannel.EMAIL)
          return conversation.participants.find(p => p !== 'support@mail.legalservicesupport.com')
        if (conversation.channel === ConversationChannel.SMS) {
          let phoneNumber = conversation.participants.find(p => p !== '+15614487828')
          if (phoneNumber) phoneNumber = formatPhoneNumber('+N-NNN-NNN-NNNN', phoneNumber)
          return phoneNumber
        }
      }

      if (conversation?.lead?.claimant)
        return `${conversation.lead.claimant.firstName} ${conversation.lead.claimant.lastName} - ${conversation.lead.matterType} - ${conversation.lead.lawFirm.name}`
    },
  },
})
