//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useQueryProvider } from 'vue-query'

export default {
  setup() {
    useQueryProvider()
  },
  computed: {
    isLeadsRoute() {
      return this.$route.name === 'leads'
    },
    menuItems() {
      const items = []
      items.push({ title: 'Leads', path: '/leads' })
      if (this.hasAdmin) items.push({ title: 'Admin', path: '/admin' })
      if (this.hasIntake) items.push({ title: 'Intake', path: '/intake/incoming-call' })
      if (this.hasRetrieval) items.push({ title: 'Case Coordination', path: '/retrieval' })

      return items
    },
    hasIntake() {
      return this.$auth.user?.roles?.includes('intake_outreach')
    },
    hasAdmin() {
      return this.$auth.user?.roles?.includes('admin')
    },
    hasMailroom() {
      return this.$auth.user?.roles?.includes('mailroom')
    },
    hasRetrieval() {
      return this.$auth.user?.roles?.includes('retrieval')
    },
    hasConversation() {
      return this.$auth.user?.roles?.includes('conversation')
    },
    hasCampaign() {
      return this.$auth.user?.roles?.includes('campaign')
    },
    hasTagManager() {
      return this.$auth.user?.roles?.includes('tag_manager')
    },

    userInitials() {
      if (!this.$auth.loggedIn) return ''
      return this.$auth.user.firstName.charAt(0) + this.$auth.user.lastName.charAt(0)
    },
  },
  methods: {
    async logout() {
      if (this.$auth.user.type === 'law_firm') {
        if (this.$auth.loggedIn) {
          await this.$auth.logout()
        }
        this.$router.push('/auth/login')
      } else {
        window.location.replace(this.$config.msLogoutUrl)
      }
    },
  },
}
