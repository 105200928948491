//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: {
    FilePond,
  },
  props: {
    leadId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    file: null,
  }),

  methods: {
    addFileHandler(error, file) {
      if (error) return this.$sentry.captureException(error)

      this.file = file
    },
  },
}
