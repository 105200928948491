//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'minimal',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
  computed: {
    errorText() {
      if (this.error.statusCode === 404) return "We couldn't find what you were looking for."
      if (this.error.statusCode === 403) return "You don't have permission to view that."

      return 'Something went wrong.'
    },
  },
}
