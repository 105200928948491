



































import moment from 'moment'
import Vue from 'vue'

import { Attachment } from '~/types/Attachment'
import { EmailMessage, Message } from '~/types/Message'

export default Vue.extend({
  props: {
    message: {
      required: true,
      type: Object as () => Message,
    },
  },

  computed: {
    subject(): string {
      const emailMessage = this.message.message as EmailMessage
      return emailMessage.subject
    },
    text(): string {
      const emailMessage = this.message.message as EmailMessage
      return emailMessage.text
    },
    attachments(): Attachment[] {
      const attachments = this.message.attachments
      if (!attachments) return []
      return [...attachments].sort((a, b) => a.id - b.id)
    },
  },

  async mounted() {
    if (!this.message.seenAt) {
      try {
        await this.$store.dispatch('conversations/markMessageAsRead', this.message.id)
      } catch (e) {
        // @ts-ignore
        this.$sentry.captureException(e)
      }
    }
  },

  methods: {
    isInternalMessage(message: Message) {
      return message.from === 'support@mail.legalservicesupport.com'
    },

    agoDate(date) {
      return moment(date).fromNow()
    },

    formatDate(date) {
      return moment(date).format('llll')
    },
    async viewAttachment(document) {
      window.open(
        (await this.$axios.$get('v1/documents/signed-urls', { params: { key: document.key } })).url,
        '_blank',
      )
    },
  },
})
