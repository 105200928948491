export enum LawFirmName {
  TRAMMEL = 'Trammell PC',
  DMA = 'D. Miller and Associates, PLLC',
  ONDER = 'OnderLaw, LLC',
  HMG = 'Hilliard Martinez Gonzales, LLP',
  PINTAS = 'Pintas & Mullins Law Firm',
  SAMPLE = 'Sample Law Firm, PLLC',
  PALERMO = 'Law Offices of Christopher Palermo & Associates',
  ARP = 'American Redress Partners LLP',
  KK = 'Krause & Kinsman Law Firm',
}

export interface LawFirm {
  id: string
  name: LawFirmName
}
