import { Plugin } from '@nuxt/types'

export const plugin = {
  isNumber(input) {
    return /^\d+$/.test(input)
  },
}

const utilsPlugin: Plugin = (_, inject) => {
  inject('utils', plugin)
}

export default utilsPlugin
