// This module houses new facilities which have not previously been persisted to the backend

import { v4 as uuid } from 'uuid'
import Vue from 'vue'

export const state = () => ({
  events: [],
})

export const getters = {
  events: state => state.events,
}

export const mutations = {
  add(state, event) {
    state.events.push(event)
  },

  update(state, { uuid, data }) {
    Vue.set(
      state.events,
      state.events.findIndex(e => e.uuid === uuid),
      {
        ...state.events.find(e => e.uuid === uuid),
        ...data,
      },
    )
  },

  remove(state, uuid) {
    const index = state.events.findIndex(e => e.uuid === uuid)
    state.events.splice(index, 1)
  },
}

export const actions = {
  async add({ commit, dispatch }, { type }) {
    const facility = await dispatch('medical-facilities/add', null, { root: true })

    commit('add', {
      uuid: uuid(),
      date: '',
      type,
      datePrecision: '',
      medicalFacility: facility.uuid,
    })
  },

  remove({ commit }, uuid) {
    commit('remove', uuid)
  },

  async save({ dispatch, getters, rootGetters }) {
    for (const event of getters.events) {
      let medicalFacility = rootGetters['medical-facilities/facilities'].find(
        f => f.uuid === event.medicalFacility,
      )

      if (!medicalFacility.createdAt) {
        medicalFacility = await dispatch('medical-facilities/save', medicalFacility.uuid, {
          root: true,
        })
      }

      // @ts-ignore
      await this.$axios.post(`/leads/${rootGetters.leadId}/medical-events`, {
        type: event.type,
        date: event.date,
        datePrecision: event.datePrecision,
        medicalFacility: { id: medicalFacility.id },
      })
    }
  },
}
