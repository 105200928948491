//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

export default {
  props: {
    date: {
      type: String || Date,
      required: true,
    },
  },
  computed: {
    agoDate() {
      return moment(this.date).fromNow()
    },
    fullDate() {
      return moment(this.date).format('L LT')
    },
  },
}
