import { Claimant } from './Claimant'
import { LawFirm } from './LawFirm'

export enum MatterType {
  ZANTAC = 'Zantac',
  OPIOID = 'Opioid',
  HERNIA = 'Hernia Mesh',
  ROUNDUP = 'Roundup',
  ELMIRON = 'Elmiron',
  HIP = 'HIP Implant',
  KNEE = 'Knee Replacement',
  PARAGARD = 'Paragard',
  INFANT_FORMULA = 'Infant Formula',
  CPAP = 'CPAP',
}

export interface Lead {
  id: string
  status: string
  disqualificationReason: string
  matterType: MatterType
  disqualification?: string
  claimant: Claimant
  lawFirm: LawFirm
  [key: string]: any
}
