import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_093b9abf from 'nuxt_plugin_plugin_093b9abf' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_fed171ce from 'nuxt_plugin_sentryserver_fed171ce' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_1addd591 from 'nuxt_plugin_sentryclient_1addd591' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_0d968ce2 from 'nuxt_plugin_plugin_0d968ce2' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_plugin_5e4ac867 from 'nuxt_plugin_plugin_5e4ac867' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_vcurrencyfield_28c2244e from 'nuxt_plugin_vcurrencyfield_28c2244e' // Source: ./v-currency-field.js (mode: 'all')
import nuxt_plugin_axios_9e4ea756 from 'nuxt_plugin_axios_9e4ea756' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_vueinputfacade_bd8f7f4c from 'nuxt_plugin_vueinputfacade_bd8f7f4c' // Source: ../plugins/vue-input-facade (mode: 'all')
import nuxt_plugin_queues_412b9710 from 'nuxt_plugin_queues_412b9710' // Source: ../plugins/queues (mode: 'all')
import nuxt_plugin_utils_3336a20a from 'nuxt_plugin_utils_3336a20a' // Source: ../plugins/utils (mode: 'all')
import nuxt_plugin_stickyteamid_4fdb28c6 from 'nuxt_plugin_stickyteamid_4fdb28c6' // Source: ../plugins/sticky-team-id (mode: 'all')
import nuxt_plugin_validation_f079ef22 from 'nuxt_plugin_validation_f079ef22' // Source: ../plugins/validation (mode: 'all')
import nuxt_plugin_tiptapvuetify_576a6ddf from 'nuxt_plugin_tiptapvuetify_576a6ddf' // Source: ../plugins/tiptap-vuetify (mode: 'all')
import nuxt_plugin_hotjar_6900f709 from 'nuxt_plugin_hotjar_6900f709' // Source: ../plugins/hotjar.ts (mode: 'client')
import nuxt_plugin_auth_74c99e3b from 'nuxt_plugin_auth_74c99e3b' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_setsentryuser_cb244450 from 'nuxt_plugin_setsentryuser_cb244450' // Source: ../plugins/set-sentry-user (mode: 'client')
import nuxt_plugin_pusher_4488ef86 from 'nuxt_plugin_pusher_4488ef86' // Source: ../plugins/pusher (mode: 'client')
import nuxt_plugin_meta_c570dd8e from 'nuxt_plugin_meta_c570dd8e' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"dockit","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"dockit - simplifies mass tort case management"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto:100,300,400,500,700,900&display=swap"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@latest\u002Fcss\u002Fmaterialdesignicons.min.css"}],"__dangerouslyDisableSanitizersByTagID":{"tailwind-cdn":["script"],"tailwind-config":["script","innerHTML"]},"script":[{"hid":"tailwind-cdn","src":"https:\u002F\u002Fcdn.tailwindcss.com?plugins=line-clamp"},{"hid":"tailwind-config","innerHTML":"\n          if (tailwind) {\n            tailwind.config = {\n              prefix: 'tw-',\n              theme: {\n                extend: {},\n              },\n              plugins: [],\n            }\n          }\n          "}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_093b9abf === 'function') {
    await nuxt_plugin_plugin_093b9abf(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_fed171ce === 'function') {
    await nuxt_plugin_sentryserver_fed171ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_1addd591 === 'function') {
    await nuxt_plugin_sentryclient_1addd591(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0d968ce2 === 'function') {
    await nuxt_plugin_plugin_0d968ce2(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_5e4ac867 === 'function') {
    await nuxt_plugin_plugin_5e4ac867(app.context, inject)
  }

  if (typeof nuxt_plugin_vcurrencyfield_28c2244e === 'function') {
    await nuxt_plugin_vcurrencyfield_28c2244e(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_9e4ea756 === 'function') {
    await nuxt_plugin_axios_9e4ea756(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_vueinputfacade_bd8f7f4c === 'function') {
    await nuxt_plugin_vueinputfacade_bd8f7f4c(app.context, inject)
  }

  if (typeof nuxt_plugin_queues_412b9710 === 'function') {
    await nuxt_plugin_queues_412b9710(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_3336a20a === 'function') {
    await nuxt_plugin_utils_3336a20a(app.context, inject)
  }

  if (typeof nuxt_plugin_stickyteamid_4fdb28c6 === 'function') {
    await nuxt_plugin_stickyteamid_4fdb28c6(app.context, inject)
  }

  if (typeof nuxt_plugin_validation_f079ef22 === 'function') {
    await nuxt_plugin_validation_f079ef22(app.context, inject)
  }

  if (typeof nuxt_plugin_tiptapvuetify_576a6ddf === 'function') {
    await nuxt_plugin_tiptapvuetify_576a6ddf(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_6900f709 === 'function') {
    await nuxt_plugin_hotjar_6900f709(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_74c99e3b === 'function') {
    await nuxt_plugin_auth_74c99e3b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_setsentryuser_cb244450 === 'function') {
    await nuxt_plugin_setsentryuser_cb244450(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pusher_4488ef86 === 'function') {
    await nuxt_plugin_pusher_4488ef86(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_c570dd8e === 'function') {
    await nuxt_plugin_meta_c570dd8e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
