













































import moment from 'moment'
import Vue from 'vue'

import { Message } from '~/types/Message'

export default Vue.extend({
  props: {
    message: {
      required: true,
      type: Object as () => Message,
    },
  },

  async mounted() {
    if (!this.message.seenAt) {
      try {
        await this.$store.dispatch('conversations/markMessageAsRead', this.message.id)
      } catch (e) {
        // @ts-ignore
        this.$sentry.captureException(e)
      }
    }
  },

  methods: {
    isInternalMessage(message: Message) {
      return message.from === '+15614487828'
    },

    agoDate(date) {
      return moment(date).fromNow()
    },

    formatDate(date) {
      return moment(date).format('llll')
    },
    hasDeliveryError(deliveryStatus) {
      return ['failed', 'undelivered'].includes(deliveryStatus)
    },
    async viewAttachment(document) {
      window.open(
        (await this.$axios.$get('v1/documents/signed-urls', { params: { key: document.key } })).url,
        '_blank',
      )
    },
  },
})
