import orderBy from 'lodash/orderBy'
import Vue from 'vue'

import resources from './plugins/resources'

export const plugins = [
  resources([
    'activity',
    'addresses',
    'answers',
    'claimant-addresses',
    'claimant-email-addresses',
    'claimant-phone-numbers',
    'claimant-representatives',
    'claimants',
    'claims',
    'copy-services',
    'document-request-invoices',
    'document-requests',
    'documents',
    'drugs',
    'devices',
    'email-addresses',
    'findings',
    'forms',
    'invoice-payments',
    'invoices',
    'key-phrases',
    'law-firms',
    'law-firm-transmission',
    'leads',
    'lead-checks',
    'lead-groups',
    'lead-lists',
    'lead-summary',
    'manufacturers',
    'mail-document',
    'matter-types',
    'medical-events',
    'medical-events-retrieval-check',
    'medical-facilities',
    'medical-facilities-needing-attention',
    'next-actions',
    'persons',
    'person-addresses',
    'person-contacts',
    'person-email-addresses',
    'person-employment-history',
    'person-insurance-policies',
    'person-phone-numbers',
    'phone-numbers',
    'reviews',
    'signnow-documents',
    'stripe-transactions',
    'users',
    'conversations',
    'insurance-policies',
    'employment-history',
    'claimant-contacts',
    'campaigns',
    'medical-event-types',
    'question-sets',
    'outreach-requests',
    'lead-tasks',
    'lead-task-events',
    'task-blockers',
    'task-blocker-events',
    'teams',
    'law-firm-transmissions',
    'campaign-targets',
    'signature-requests',
  ]),
]

export const state = () => ({
  lead: null,
  retainers: [],
  signNowDocumentSendChannel: null,
  stateOptions,
  claimantCall: null,
  isNewLead: false,
})

export const getters = {
  outreachRequestTypeOptions: () => [
    'Affidavit Needed',
    'CNR',
    'Death Certificate Needed',
    'Demographic Info Needed (ie. Full SSN, DOB)',
    'Ink Signature Needed',
    'Law Firm Requires More Info',
    'New E-Signature Needed',
    'NOK Affidavit/ POA Docs Needed',
    "Records Don't Support Claim",
    'Other',
  ],
  invoicePaymentMethodOptions: () => ['ACH', 'Check', 'Credit Card', 'Emburse'],
  medicalFacilityPreferredDocumentRequestMethodOptions: () => [
    { text: 'Fax', value: 'fax' },
    { text: 'Email', value: 'email' },
    { text: 'Mail', value: 'mail' },
  ],
  medicalFacilityPreferredFollowUpMethodOptions: () => [
    { text: 'Phone', value: 'phone' },
    { text: 'Fax', value: 'fax' },
    { text: 'Email', value: 'email' },
    { text: 'Copy Service', value: 'copy_service' },
    { text: 'Website', value: 'website' },
  ],
  documentRequestStatusOptions: () => [
    { text: 'To Be Requested', value: 'to_be_requested' },
    { text: 'In Progress', value: 'in_progress' },
    { text: 'Pending Pre-Payment', value: 'pending_pre_payment' },
    { text: 'Received', value: 'received' },
    { text: 'Rejected', value: 'rejected' },
    { text: 'Failed', value: 'failed' },
    { text: 'Closed', value: 'closed' },
  ],
  documentRequestRejectedReasonOptions: () => [
    { text: 'Illegible Authorization', value: 'illegible_authorization' },
    { text: 'Altered Authorization', value: 'altered_authorization' },
    { text: 'Missing Authorization', value: 'missing_authorization' },
    { text: 'Expired Authorization', value: 'expired_authorization' },
    {
      text: 'Unable to Locate Chart with Demographics provided',
      value: 'unable_to_locate_chart_with_demographics_provided',
    },
    { text: 'Records exceed Retention Policy', value: 'records_exceed_retention_policy' },
    { text: 'Provider Not Identified', value: 'provider_not_identified' },
    { text: 'Illegible Death Certificate', value: 'illegible_death_certificate' },
    { text: 'HITECH Rejection', value: 'hitech_rejection' },
    { text: 'Certificate of No Records', value: 'certificate_of_no_records' },
    { text: 'Record Purged', value: 'records_purged' },
    { text: 'Client Denied Request', value: 'client_denied_request' },
    { text: 'E-signature Rejection', value: 'e-signature_rejection' },
    { text: 'Unacceptable Transmission Method', value: 'unacceptable_transmission_method' },
    { text: 'Wrong Facility', value: 'wrong_facility' },
    { text: 'Date of Signature', value: 'date_of_signature' },
    { text: 'Cannot Verify Signature', value: 'cannot_verify_signature' },
    { text: 'Facility Shutdown', value: 'facility_shutdown' },
    { text: 'Facility Non-Response', value: 'facility_non-responsive' },
    { text: 'Custom Hipaa Required', value: 'custom_hipaa_required' },
    { text: 'PoA Documentation Required', value: 'poa_documentation_required' },
    { text: 'Date Range of Request', value: 'date_range_of_request' },
    { text: 'Missing Middle Initial', value: 'missing_middle_initial' },
    { text: 'Clarify Claimant Address', value: 'clarify_claimant_address' },
    { text: 'Claimant Alternate Name(s)', value: 'claimant_alternate_names' },
    { text: 'Other', value: 'other' },
  ],
  documentRequestClosedReasonOptions: () => [
    { text: 'Records Not Needed', value: 'records_not_needed' },
    { text: 'Rejected', value: 'rejected' },
    { text: 'Facility Shutdown', value: 'facility_shutdown' },
    { text: 'Facility Non-Responsive', value: 'facility_non_responsive' },
    { text: 'Duplicate', value: 'duplicate' },
    { text: 'Claimant Opted Out', value: 'claimant_opted_out' },
    { text: 'Claimant Unreachable', value: 'claimant_unreachable' },
    { text: 'Lead Canceled', value: 'lead_canceled' },
  ],

  timeZoneOptions: () => [
    'US/Eastern',
    'US/East-Indiana',
    'US/Indiana-Starke',
    'US/Central',
    'US/Michigan',
    'US/Mountain',
    'US/Arizona',
    'US/Pacific',
    'US/Hawaii',
    'US/Aleutian',
  ],
  leadStatusOptions: () => [
    { value: 'intake', text: 'Intake' },
    { value: 'pre-retrieval', text: 'Pre-retrieval' },
    { value: 'retrieval', text: 'Retrieval' },
    { value: 'internal review', text: 'Internal Review' },
    { value: 'proven phase 1', text: 'Proven Phase 1' },
    { value: 'proven', text: 'Proven' },
    { value: 'canceled', text: 'Canceled' },
    { value: 'disproven', text: 'Disproven' },
  ],

  releaseTemplates: () => [
    { name: 'arp-roundup', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'arp-roundup-shifted', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'arp-3-m-ear-plugs', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'at-opioid', hasFactoryTemplate: false, hasWetSignatureTask: true },
    { name: 'dma-opioid', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'dma-opioid-v2', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'dma-opioid-shifted', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'dma-opioid-texas-auth', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'dma-zantac', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'dma-zantac-legalcalls', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'dma-paragard', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'elg-zantac', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'elg-toxic-baby-food', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'elg-toxic-baby-food-shifted', hasFactoryTemplate: true, hasWetSignatureTask: false },
    { name: 'elg-toxic-baby-food-lake', hasFactoryTemplate: true, hasWetSignatureTask: false },
    { name: 'hmg-opioid', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'hmg-opioid-rp', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'hmg-opioid-nrr', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'hmg-opioid-ndc', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'hmg-hernia-mesh', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'kk-hernia-mesh', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'lake-roundup-ny', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'lake-zantac', hasFactoryTemplate: true, hasWetSignatureTask: false },
    { name: 'lake-hernia-mesh', hasFactoryTemplate: true, hasWetSignatureTask: false },
    { name: 'lake-hernia-mesh-shifted', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'lake-roundup', hasFactoryTemplate: true, hasWetSignatureTask: false },
    { name: 'lake-roundup-ny-shifted', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'onder-cpap', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'pintas-zantac', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'pintas-hernia-mesh', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'pintas-puerto-rico-hernia-mesh', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'pintas-roundup', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'palermo-zantac', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'palermo-hernia-mesh', hasFactoryTemplate: false, hasWetSignatureTask: true },
    { name: 'rdp-roundup', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'sample-hernia-mesh', hasFactoryTemplate: false, hasWetSignatureTask: false },
    { name: 'lake-talc', hasFactoryTemplate: true, hasWetSignatureTask: true },
    { name: 'lake-firefoam', hasFactoryTemplate: true, hasWetSignatureTask: true },
  ],
  wetSignatureReleaseTemplates: (_state, getters) =>
    getters.releaseTemplates
      .filter(template => template.hasWetSignatureTask)
      .map(template => template.name),

  releaseTemplateOptions: (_state, getters) => getters.releaseTemplates.map(t => t.name).sort(),

  stampableTemplateOptions: (_state, getters) =>
    getters.releaseTemplates
      .filter(t => t.hasFactoryTemplate)
      .map(t => t.name)
      .sort(),

  documentTypesConfig: () => ({
    'Medical Records': {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    'Unredacted Medical Records': {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    Rejection: {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    'Claimant Correspondence': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Claimant Search Report': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Discovery Document': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Representative Document': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Request Confirmation': {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    retainer: {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    Invoice: {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    Password: {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    'Medical Release': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: true,
    },
    'eSignature Audit Trail': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Proof of Use Affidavit': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Next of Kin Affidavit': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Sign Up Sheet': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Law Firm Correspondence': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'DD-214': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Document Request Cancellation': {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    'Short Form Complaint': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Civil Cover Sheet': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Manual Document Request': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Invoice Receipt': {
      requireDocumentRequestUpdate: true,
      requireMedicalEvents: true,
      showDocumentRequest: true,
    },
    'Short Form Memo': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: true,
    },
    'Medical Chronology': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: true,
    },
    'Law Firm Notes': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Imaging Records': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Baby/Birth Pictures': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Military Records/Docs': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Postage/Tracking': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Returned Mail': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: true,
    },
    'Proof of Use Certification': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    Signature: {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    Initials: {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Signature Release Agreement': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Confirmed Questionnaire': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'Tolling Documents': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'IntakeQ Form': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'IntakeQ Records': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
    'IntakeQ Certification': {
      requireDocumentRequestUpdate: false,
      requireMedicalEvents: false,
      showDocumentRequest: false,
    },
  }),
  documentTypeOptions: (_, getters) => Object.keys(getters.documentTypesConfig).sort(),

  documentTypeSubTypes: (_, getters) => ({
    'Law Firm Correspondence': getters.lawFirmDocumentSubTypes,
    'Representative Document': getters.repDocumentSubTypes,
    'Medical Release': getters.medicalReleaseSubtypes,
    'Claimant Correspondence': getters.claimantDocumentSubTypes,
    'Discovery Document': getters.discoveryDocumentSubtypes,
  }),
  claimantDocumentSubTypes: () =>
    [
      'Bankruptcy Document',
      'Client Deceased',
      'Client Note',
      'Divorce Certificate',
      'Driver’s License',
      'Health Insurance Card',
      'HIPAA – Wet',
      'HITECH – Wet ',
      'Insurance Explanation of Benefits',
      'Marriage Certificate',
      'Passport',
      'Probate Document',
      'Proof of Use',
      'Signed Affidavit',
      'Signed Settlement Release',
      'Social Security Card',
      'Birth Certificate',
    ].sort(),
  discoveryDocumentSubtypes: () =>
    [
      'Bellwether Supplement',
      'Census Certification',
      'Census Form',
      'Plaintiff Profile Form',
      'Plaintiff Fact Sheet',
    ].sort(),
  repDocumentSubTypes: () =>
    [
      'Bankruptcy Document',
      'Client Deceased',
      'Death Certificate',
      'Divorce Certificate',
      'Driver’s License',
      'Last Will & Testament',
      'Letters of Appointment',
      'Letters of Testamentary',
      'Marriage Certificate',
      'Passport',
      'Power of Attorney',
      'Signed Affidavit',
      'Signed Settlement Release',
      'Birth Certificate',
      'Adoption Document',
      'Guardianship Document',
    ].sort(),
  lawFirmDocumentSubTypes: () =>
    [
      'Case Filing',
      'Client Intake',
      'Co-Counsel Authorization',
      'Co-Counsel Communication',
      'Co-Counsel Complaint Filed',
      'Insurance Correspondence',
      'Insurance Explanation of Benefits',
      'Lien File',
      'Motion to Dismiss without Prejudice',
      'Notice of Court Appearance',
      'Plaintiff Profile Form',
      'Probate Document',
      'Settled',
      'Declination Letter',
      'Letter to Client',
      'External Invoice',
    ].sort(),
  medicalReleaseSubtypes: () => [
    'unsigned',
    'blank',
    'eSignature',
    'signed',
    'VA wet signature',
    'wet signature',
    'wet signature - reformatted',
  ],
  retainedLeadSources: () => [
    'legalcalls',
    'plumtreedata',
    'cag',
    'legalconversioncenter',
    'velawcity',
    'nibdirect',
    'tortgroup',
    'dmaadvocates',
    'persist',
    'reliancelitigation',
  ],
  representativeTypeOptions: () => [
    'guardian',
    'executor of estate',
    'power of attorney',
    'next of kin',
    'unknown',
  ],
  leadId: state => state.lead.id,
  stateOptions: state => state.stateOptions,
  claimant: state => state.lead.claimant,
  lawFirm: state => state.lead.lawFirm,
  retainers: state => state.retainers,
  lead: state => state.lead,
  signNowDocumentSendChannel: state => state.signNowDocumentSendChannel,
  isNewLead: state => state.isNewLead,

  lawFirmReviewStatusOptions: () => [
    { value: 'approved', text: 'Approved' },
    { value: 'declined', text: 'Declined' },
    { value: 'not sent', text: 'Not sent' },
    { value: 'pending approval', text: 'Pending approval' },
  ],

  documentRequestResendReasons: () => [
    { text: 'Facility Never Received', value: 'Facility Never Received' },
    {
      text: 'Unacceptable Transmission Method',
      value: 'Unacceptable Transmission Method',
    },
    { text: 'Date Range of Request', value: 'Date Range of Request' },
    { text: 'Missing DOB', value: 'Missing DOB' },
    { text: 'Missing SSN', value: 'Missing SSN' },
    { text: 'Missing Middle Initial', value: 'Missing Middle Initial' },
    {
      text: 'Incorrect Claimant Address',
      value: 'Incorrect Claimant Address',
    },
    { text: 'Needs ID', value: 'Needs ID' },
    { text: 'New Signature Needed', value: 'New Signature Needed' },
    { text: 'Representative Documents', value: 'Representative Documents' },
    { text: 'Custom HIPAA Required', value: 'Custom HIPAA Required' },
    { text: 'Other', value: 'Other' },
  ],
}

export const mutations = {
  setLead(state, lead) {
    state.lead = lead
  },
  setRetainer(state, data) {
    Vue.set(
      state.retainers,
      state.retainers.find(retainer => retainer.id === data.id)
        ? state.retainers.findIndex(retainer => retainer.id === data.id)
        : state.retainers.length,
      data,
    )
  },
  updateLeadStatus(state, status) {
    state.lead = { ...state.lead, status }
  },
  updateSignNowDocumentSendChannel(state, channel) {
    state.signNowDocumentSendChannel = channel
  },
  updateClaimant(state, claimant) {
    state.lead.claimant = claimant
  },
  isNewLead(state, isNewLead) {
    state.isNewLead = isNewLead
  },
}

export const actions = {
  async getLeadByLegacyId({ commit }, legacyLeadId) {
    // @ts-ignore
    const { data } = await this.$axios.get(`leads/import/${legacyLeadId}`)
    commit('setLead', data)

    for (const retainer of data.retainers) {
      commit('setRetainer', retainer)
    }
  },
  async updateLead({ commit }, { leadId, data: leadData }) {
    // @ts-ignore
    const { data } = await this.$axios.patch(`leads/${leadId}`, leadData)
    commit('updateLeadStatus', data.status)
  },
  async getRetainer({ commit, getters }, id) {
    // @ts-ignore
    const { data } = await this.$axios.get(`leads/${getters.leadId}/retainers/${id}`)

    commit('setRetainer', data)

    return data
  },
  async sendRetainer({ commit }, { leadId, channel, signNowTemplateId }) {
    // @ts-ignore
    const { data } = await this.$axios.post(`leads/${leadId}/retainers`, {
      channel,
      signNowTemplateId,
    })
    commit('setRetainer', data)
    commit('updateSignNowDocumentSendChannel', channel)
  },
  async updateClaimant({ commit, getters }, claimant) {
    const { firstName, middleName, lastName, socialSecurityNumber, dateOfBirth } = claimant
    // @ts-ignore
    const { data: updatedClaimantData } = await this.$axios.patch(
      `/claimants/${getters.claimant.id}`,
      {
        firstName,
        middleName,
        lastName,
        socialSecurityNumber: socialSecurityNumber?.replace(/\D/g, ''),
        dateOfBirth: new Date(dateOfBirth).toISOString().slice(0, 10),
      },
    )
    commit('updateClaimant', updatedClaimantData)
  },
}

const stateOptions = orderBy(
  [
    { text: 'Alaska', value: 'AK' },
    { text: 'Alabama', value: 'AL' },
    { text: 'Arkansas', value: 'AR' },
    { text: 'Arizona', value: 'AZ' },
    { text: 'California', value: 'CA' },
    { text: 'Colorado', value: 'CO' },
    { text: 'Connecticut', value: 'CT' },
    { text: 'Washington DC', value: 'DC' },
    { text: 'Delaware', value: 'DE' },
    { text: 'Florida', value: 'FL' },
    { text: 'Georgia', value: 'GA' },
    { text: 'Hawaii', value: 'HI' },
    { text: 'Iowa', value: 'IA' },
    { text: 'Idaho', value: 'ID' },
    { text: 'Illinois', value: 'IL' },
    { text: 'Indiana', value: 'IN' },
    { text: 'Kansas', value: 'KS' },
    { text: 'Kentucky', value: 'KY' },
    { text: 'Louisiana', value: 'LA' },
    { text: 'Massachusetts', value: 'MA' },
    { text: 'Maryland', value: 'MD' },
    { text: 'Maine', value: 'ME' },
    { text: 'Michigan', value: 'MI' },
    { text: 'Minnesota', value: 'MN' },
    { text: 'Missouri', value: 'MO' },
    { text: 'Mississippi', value: 'MS' },
    { text: 'Montana', value: 'MT' },
    { text: 'North Carolina', value: 'NC' },
    { text: 'North Dakota', value: 'ND' },
    { text: 'Nebraska', value: 'NE' },
    { text: 'New Hampshire', value: 'NH' },
    { text: 'New Jersey', value: 'NJ' },
    { text: 'New Mexico', value: 'NM' },
    { text: 'Nevada', value: 'NV' },
    { text: 'New York', value: 'NY' },
    { text: 'Ohio', value: 'OH' },
    { text: 'Oklahoma', value: 'OK' },
    { text: 'Oregon', value: 'OR' },
    { text: 'Pennsylvania', value: 'PA' },
    { text: 'Puerto Rico', value: 'PR' },
    { text: 'Rhode Island', value: 'RI' },
    { text: 'South Carolina', value: 'SC' },
    { text: 'South Dakota', value: 'SD' },
    { text: 'Tennessee', value: 'TN' },
    { text: 'Texas', value: 'TX' },
    { text: 'Utah', value: 'UT' },
    { text: 'Virginia', value: 'VA' },
    { text: 'Vermont', value: 'VT' },
    { text: 'Washington', value: 'WA' },
    { text: 'Wisconsin', value: 'WI' },
    { text: 'West Virginia', value: 'WV' },
    { text: 'Wyoming', value: 'WY' },
  ],
  ['text'],
  ['asc'],
)
