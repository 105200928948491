import debounce from 'lodash/debounce'

/**
 * this store module is responsible for reacting to pusher task events
 */
export const actions = {
  taskBlockerUpdate({ rootGetters, dispatch }, blocker) {
    // pusher event not emitting blocker with task joined, so lookup task in store
    const storeTask = rootGetters['lead-tasks/all'].find(t => t.id === blocker.taskId)

    // if the task is not in the store, return early and don't try to associate the blocker update with a task
    if (!storeTask) return

    if (blocker.resolvedAt === null) {
      // intentional spreads to clone object and break vuex store refs
      const filteredBlockers = storeTask.blockers
        ? storeTask.blockers.filter(b => b.id !== blocker.id)
        : []
      const task = {
        ...storeTask,
        blockers: [...filteredBlockers, blocker],
      }
      // update the task in the store
      dispatch('taskUpdate', task)
    } else {
      // intentional spread to clone object and break vuex store refs
      const storeBlockers = storeTask.blockers ? [...storeTask.blockers] : []
      const task = { ...storeTask, blockers: storeBlockers.filter(b => b.id !== blocker.id) }
      // update the task in the store
      dispatch('taskUpdate', task)
    }
    dispatch('getBlockerEvents', {
      sort: [{ field: 'triggeredAt', order: 'DESC' }],
      // @ts-ignore
      search: { blockerId: blocker.id },
      itemsPerPage: 100,
    })
  },

  taskUpdate({ commit, rootGetters, dispatch }, task) {
    const isForCurrentLead =
      task.leadId && rootGetters['lead-tasks/all'].map(t => t.leadId).includes(task.leadId)
    const isForCurrentDocument =
      task.documentId &&
      rootGetters['lead-tasks/all'].map(t => t.documentId).includes(task.documentId)

    // Lead Tasks
    if (isForCurrentLead) {
      const existingTask = rootGetters['lead-tasks/all'].find(t => t.id === task.id)
      existingTask
        ? commit('lead-tasks/update', { existingTask, ...task }, { root: true })
        : dispatch('getLeadTasks', task.leadId)
    }

    // User Tasks
    if (rootGetters['user-tasks/tasks'].map(t => t.id).includes(task.id))
      commit('user-tasks/update', task, {
        root: true,
      })

    // Queue Tasks
    const teamId = getTeamId()

    if (teamId && rootGetters['resources/lead-tasks/all'].length) {
      const isInQueueTaskState = rootGetters['resources/lead-tasks/all']
        .map(t => t.id)
        .includes(task.id)

      isInQueueTaskState
        ? commit('resources/lead-tasks/update', task, { root: true })
        : dispatch('getQueueTasks')
    }

    // Task Events
    if (isForCurrentLead || isForCurrentDocument) {
      dispatch('getTaskEvents', {
        sort: [{ field: 'createdAt', order: 'DESC' }],
        search: {
          leadTaskId: task.id,
        },
        itemsPerPage: 100,
      })
    }
  },

  getLeadTasks: debounce(({ dispatch }, leadId) => {
    dispatch('lead-tasks/get', leadId, { root: true })
  }, 1500),

  getQueueTasks: debounce(({ dispatch }) => {
    dispatch('resources/lead-tasks/refresh', null, { root: true })
  }, 1500),

  getTaskEvents: debounce(({ dispatch }, body) => {
    dispatch('resources/lead-task-events/get', body, { root: true })
  }, 1500),

  getBlockerEvents: debounce(({ dispatch }, body) => {
    dispatch('resources/task-blocker-events/get', body, { root: true })
  }, 1500),
}

function getTeamId() {
  return window &&
    window.location.pathname.includes('queues') &&
    window.location.pathname.split('/')[2] !== 'na'
    ? window.location.pathname.split('/')[2]
    : null
}
