import Vue from 'vue'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface State {
  all: any[]
  taskConfig: any[]
}

export const state = (): State => ({
  all: [],
  taskConfig: [],
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  all: state => state.all,
  taskConfig: state => state.taskConfig,
}

export const mutations: MutationTree<RootState> = {
  set(state, all) {
    state.all = all
  },
  setTaskConfig(state, taskConfig) {
    state.taskConfig = taskConfig
  },
  update(state, task) {
    const taskIndex = state.all.findIndex(t => t.id === task.id)
    if (taskIndex === -1) return

    Vue.set(state.all, taskIndex, { ...state.all[taskIndex], ...task })
  },
}

export const actions: ActionTree<RootState, RootState> = {
  async get({ commit }, leadId: string) {
    if (!leadId) return
    const { data } = await this.$axios.$get('v1/lead-tasks', {
      params: {
        s: { leadId },
        limit: 100,
        join: [
          'snoozedByUser',
          'blockers.assignedToUser',
          'blockers.blockedByTeam',
          'blockers.createdBy',
        ],
      },
    })
    commit('set', data)

    const lead = await this.$axios.$get(`v1/leads/${leadId}`, {
      params: { join: ['dockitCampaign'] },
    })
    commit('setTaskConfig', lead.dockitCampaign?.taskConfig)
  },

  async getDocumentTasks({ commit }, documentId: string) {
    if (!documentId) return
    const { data } = await this.$axios.$get('v1/lead-tasks', {
      params: {
        s: { documentId },
        limit: 100,
        join: [
          'snoozedByUser',
          'blockers.assignedToUser',
          'blockers.blockedByTeam',
          'blockers.createdBy',
        ],
      },
    })
    commit('set', data)

    commit('setTaskConfig', [])
  },

  async getById({ commit }, taskId: string) {
    if (!taskId) return
    const { data } = await this.$axios.$get('v1/lead-tasks', {
      params: { s: { id: taskId }, limit: 100, join: ['snoozedByUser'] },
    })
    commit('set', data)
  },
}
