













import moment from 'moment'
import Vue from 'vue'

export default Vue.extend({
  props: {
    date: {
      required: true,
      type: Date,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('llll')
    },
  },
})
