import {
  QueryFilter,
  QueryFilterArr,
  QueryJoin,
  QueryJoinArr,
  QuerySort,
  QuerySortArr,
  RequestQueryBuilder,
} from '@nestjsx/crud-request'
import { Plugin } from '@nuxt/types'

interface QueryOptions {
  sort?: QuerySort | QuerySortArr | Array<QuerySort | QuerySortArr>
  page?: number
  itemsPerPage?: number
  search?: any
  filter?: QueryFilter | QueryFilterArr | Array<QueryFilter | QueryFilterArr>
  join?: QueryJoin | QueryJoinArr | Array<QueryJoin | QueryJoinArr>
  version?: string
}

interface api {
  get(resource: string, options: QueryOptions)
  getById(resource: string, options: QueryOptions)
  patch(resource: string, state, options: QueryOptions)
}
declare module 'vue/types/vue' {
  interface Vue {
    $api: api
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $api: api
  }
  interface Context {
    $api: api
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $queues(message: string): void
  }
}

const apiPlugin: Plugin = ({ $axios }, inject) => {
  async function get(
    resource,
    { sort = [], page = 1, itemsPerPage = 10, search, join = [], version = 'v1' },
  ) {
    const queryBuilder = RequestQueryBuilder.create()
      .setLimit(itemsPerPage)
      .setPage(page)
      .search(search)
      .sortBy(sort)

    if (join?.length) {
      queryBuilder.setJoin(join)
    }
    const queryString = queryBuilder.query()

    const result = await $axios.get(`${version}/${resource}?${queryString}`)
    return result.data
  }

  async function getById(
    resource: string,
    options:
      | string
      | number
      | {
          id: number
          join: QueryJoin | QueryJoinArr | Array<QueryJoin | QueryJoinArr>
        },
  ) {
    let id
    const queryBuilder = RequestQueryBuilder.create()
    if (typeof options === 'number' || typeof options === 'string') {
      id = options
    } else {
      id = options.id
      if (options?.join) {
        queryBuilder.setJoin(options.join)
      }
    }
    const queryString = queryBuilder.query() ? '?' + queryBuilder.query() : ''

    const result = await $axios.get(`v1/${resource}/${id}${queryString}`)
    return result.data
  }

  async function patch(resource, state, { data, options = { parentRoute: null, join: [] } }) {
    const queryBuilder = RequestQueryBuilder.create()

    if (options.join?.length) {
      queryBuilder.setJoin(options.join)
    }

    const queryString = queryBuilder.query()
    const result = await $axios.$patch(
      `v1/${options.parentRoute ? options.parentRoute + '/' : ''}${resource}/${data.id}${
        queryString.length > 0 ? `?${queryString}` : ''
      }`,
      data,
    )

    return update(state, { id: data.id, data: result })
  }

  inject('api', {
    get: async (resource, options) => {
      try {
        return await get(resource, options)
      } catch (e) {
        console.error('$api.get:', e)
      }
    },
    getById: async (resource, options) => {
      try {
        return await getById(resource, options)
      } catch (e) {
        console.error('$api.getById:', e)
      }
    },
    patch: async (resource, state, options) => {
      try {
        return await patch(resource, state, options)
      } catch (e) {
        console.error('$api.patch:', e)
      }
    },
  })
}

export default apiPlugin

function update(state, { id, data }) {
  const item = state.find(f => f.id === id)
  if (!item) {
    return state
  }

  const index = state.findIndex(f => f.id === id)
  state[index] = { ...item, ...data }
  return state
}
