const middleware = {}

middleware['admin'] = require('../middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['campaign'] = require('../middleware/campaign.ts')
middleware['campaign'] = middleware['campaign'].default || middleware['campaign']

middleware['engineering'] = require('../middleware/engineering.ts')
middleware['engineering'] = middleware['engineering'].default || middleware['engineering']

middleware['intake-outreach'] = require('../middleware/intake-outreach.ts')
middleware['intake-outreach'] = middleware['intake-outreach'].default || middleware['intake-outreach']

middleware['leads-redirect'] = require('../middleware/leads-redirect.ts')
middleware['leads-redirect'] = middleware['leads-redirect'].default || middleware['leads-redirect']

middleware['mailroom'] = require('../middleware/mailroom.ts')
middleware['mailroom'] = middleware['mailroom'].default || middleware['mailroom']

middleware['redirect'] = require('../middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['retrieval'] = require('../middleware/retrieval.ts')
middleware['retrieval'] = middleware['retrieval'].default || middleware['retrieval']

middleware['review'] = require('../middleware/review.ts')
middleware['review'] = middleware['review'].default || middleware['review']

middleware['tag_manager'] = require('../middleware/tag_manager.ts')
middleware['tag_manager'] = middleware['tag_manager'].default || middleware['tag_manager']

middleware['tasks-redirect'] = require('../middleware/tasks-redirect.ts')
middleware['tasks-redirect'] = middleware['tasks-redirect'].default || middleware['tasks-redirect']

export default middleware
