import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _05aad7ae = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _01faf253 = () => interopDefault(import('../pages/aging.vue' /* webpackChunkName: "pages/aging" */))
const _7e4852b0 = () => interopDefault(import('../pages/intake/index.vue' /* webpackChunkName: "pages/intake/index" */))
const _a1c3ffe0 = () => interopDefault(import('../pages/lead-import/index.vue' /* webpackChunkName: "pages/lead-import/index" */))
const _4f50e371 = () => interopDefault(import('../pages/leads/index.vue' /* webpackChunkName: "pages/leads/index" */))
const _4161264e = () => interopDefault(import('../pages/retrieval/index.vue' /* webpackChunkName: "pages/retrieval/index" */))
const _742177bd = () => interopDefault(import('../pages/admin/campaigns/index.vue' /* webpackChunkName: "pages/admin/campaigns/index" */))
const _ecaca554 = () => interopDefault(import('../pages/admin/charges.vue' /* webpackChunkName: "pages/admin/charges" */))
const _1e68b218 = () => interopDefault(import('../pages/admin/devices.vue' /* webpackChunkName: "pages/admin/devices" */))
const _3dc647aa = () => interopDefault(import('../pages/admin/drugs.vue' /* webpackChunkName: "pages/admin/drugs" */))
const _13ee9742 = () => interopDefault(import('../pages/admin/invoices.vue' /* webpackChunkName: "pages/admin/invoices" */))
const _07c9ee83 = () => interopDefault(import('../pages/admin/key-phrases.vue' /* webpackChunkName: "pages/admin/key-phrases" */))
const _43b7dfc6 = () => interopDefault(import('../pages/admin/lead-lists.vue' /* webpackChunkName: "pages/admin/lead-lists" */))
const _3d89c9ce = () => interopDefault(import('../pages/admin/manufacturers.vue' /* webpackChunkName: "pages/admin/manufacturers" */))
const _71d10c92 = () => interopDefault(import('../pages/admin/tags.vue' /* webpackChunkName: "pages/admin/tags" */))
const _23de630d = () => interopDefault(import('../pages/admin/teams.vue' /* webpackChunkName: "pages/admin/teams" */))
const _228fe80a = () => interopDefault(import('../pages/admin/user-permissions.vue' /* webpackChunkName: "pages/admin/user-permissions" */))
const _3c4d0a23 = () => interopDefault(import('../pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _58d9a97b = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _0f6c8e68 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _e90e0692 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _8ba08c0a = () => interopDefault(import('../pages/intake/incoming-call.vue' /* webpackChunkName: "pages/intake/incoming-call" */))
const _1636a19a = () => interopDefault(import('../pages/outreach/medical-release.vue' /* webpackChunkName: "pages/outreach/medical-release" */))
const _57281835 = () => interopDefault(import('../pages/retrieval/copy-services/index.vue' /* webpackChunkName: "pages/retrieval/copy-services/index" */))
const _57a9e296 = () => interopDefault(import('../pages/retrieval/document-requests/index.vue' /* webpackChunkName: "pages/retrieval/document-requests/index" */))
const _04a04fee = () => interopDefault(import('../pages/retrieval/escalated.vue' /* webpackChunkName: "pages/retrieval/escalated" */))
const _6281956a = () => interopDefault(import('../pages/retrieval/exception.vue' /* webpackChunkName: "pages/retrieval/exception" */))
const _3d5d3fb8 = () => interopDefault(import('../pages/retrieval/lead-groups/index.vue' /* webpackChunkName: "pages/retrieval/lead-groups/index" */))
const _7212b098 = () => interopDefault(import('../pages/retrieval/medical-facilities/index.vue' /* webpackChunkName: "pages/retrieval/medical-facilities/index" */))
const _60f6bb78 = () => interopDefault(import('../pages/retrieval/upload/index.vue' /* webpackChunkName: "pages/retrieval/upload/index" */))
const _1c12fc5c = () => interopDefault(import('../pages/admin/campaigns/matter-types.vue' /* webpackChunkName: "pages/admin/campaigns/matter-types" */))
const _478463f5 = () => interopDefault(import('../pages/admin/campaigns/medical-event-types.vue' /* webpackChunkName: "pages/admin/campaigns/medical-event-types" */))
const _4ad491f4 = () => interopDefault(import('../pages/admin/campaigns/question-sets/index.vue' /* webpackChunkName: "pages/admin/campaigns/question-sets/index" */))
const _2485c0d9 = () => interopDefault(import('../pages/retrieval/upload/3m-records.vue' /* webpackChunkName: "pages/retrieval/upload/3m-records" */))
const _6811c2ee = () => interopDefault(import('../pages/admin/campaigns/question-sets/_id.vue' /* webpackChunkName: "pages/admin/campaigns/question-sets/_id" */))
const _ff977536 = () => interopDefault(import('../pages/admin/campaigns/_id.vue' /* webpackChunkName: "pages/admin/campaigns/_id" */))
const _7899c02e = () => interopDefault(import('../pages/retrieval/copy-services/_copyServiceId.vue' /* webpackChunkName: "pages/retrieval/copy-services/_copyServiceId" */))
const _51273a5d = () => interopDefault(import('../pages/retrieval/document-requests/_id.vue' /* webpackChunkName: "pages/retrieval/document-requests/_id" */))
const _057dc8e8 = () => interopDefault(import('../pages/retrieval/lead-groups/_id.vue' /* webpackChunkName: "pages/retrieval/lead-groups/_id" */))
const _9099f1c8 = () => interopDefault(import('../pages/retrieval/medical-facilities/_id.vue' /* webpackChunkName: "pages/retrieval/medical-facilities/_id" */))
const _3a27f1b5 = () => interopDefault(import('../pages/documents/_documentId.vue' /* webpackChunkName: "pages/documents/_documentId" */))
const _0d78c710 = () => interopDefault(import('../pages/documents/_documentId/index.vue' /* webpackChunkName: "pages/documents/_documentId/index" */))
const _64968e78 = () => interopDefault(import('../pages/documents/_documentId/tasks/_taskId.vue' /* webpackChunkName: "pages/documents/_documentId/tasks/_taskId" */))
const _04dc4195 = () => interopDefault(import('../pages/leads/_leadId.vue' /* webpackChunkName: "pages/leads/_leadId" */))
const _42b48458 = () => interopDefault(import('../pages/leads/_leadId/index.vue' /* webpackChunkName: "pages/leads/_leadId/index" */))
const _457c950e = () => interopDefault(import('../pages/leads/_leadId/activity.vue' /* webpackChunkName: "pages/leads/_leadId/activity" */))
const _d82089ba = () => interopDefault(import('../pages/leads/_leadId/blockers.vue' /* webpackChunkName: "pages/leads/_leadId/blockers" */))
const _6ae4a43e = () => interopDefault(import('../pages/leads/_leadId/documents.vue' /* webpackChunkName: "pages/leads/_leadId/documents" */))
const _03a4cdd8 = () => interopDefault(import('../pages/leads/_leadId/import-data.vue' /* webpackChunkName: "pages/leads/_leadId/import-data" */))
const _3d0baf3a = () => interopDefault(import('../pages/leads/_leadId/lift-signature/index.vue' /* webpackChunkName: "pages/leads/_leadId/lift-signature/index" */))
const _62428c35 = () => interopDefault(import('../pages/leads/_leadId/outreach-requests/index.vue' /* webpackChunkName: "pages/leads/_leadId/outreach-requests/index" */))
const _6802b823 = () => interopDefault(import('../pages/leads/_leadId/signature-requests.vue' /* webpackChunkName: "pages/leads/_leadId/signature-requests" */))
const _35f8cbcd = () => interopDefault(import('../pages/leads/_leadId/smart-advocate-notes.vue' /* webpackChunkName: "pages/leads/_leadId/smart-advocate-notes" */))
const _08bc8097 = () => interopDefault(import('../pages/leads/_leadId/tasks/index.vue' /* webpackChunkName: "pages/leads/_leadId/tasks/index" */))
const _ac9cd298 = () => interopDefault(import('../pages/leads/_leadId/tasks/outreach-requests/index.vue' /* webpackChunkName: "pages/leads/_leadId/tasks/outreach-requests/index" */))
const _599c07b4 = () => interopDefault(import('../pages/leads/_leadId/tasks/outreach-requests/_outreachRequestId.vue' /* webpackChunkName: "pages/leads/_leadId/tasks/outreach-requests/_outreachRequestId" */))
const _1ac166f4 = () => interopDefault(import('../pages/leads/_leadId/lift-signature/_documentId.vue' /* webpackChunkName: "pages/leads/_leadId/lift-signature/_documentId" */))
const _1dc3cbd3 = () => interopDefault(import('../pages/leads/_leadId/outreach-requests/_outreachRequestId.vue' /* webpackChunkName: "pages/leads/_leadId/outreach-requests/_outreachRequestId" */))
const _232fc0a4 = () => interopDefault(import('../pages/leads/_leadId/tasks/_taskId.vue' /* webpackChunkName: "pages/leads/_leadId/tasks/_taskId" */))
const _09f1e207 = () => interopDefault(import('../pages/pdf-editor/_documentId.vue' /* webpackChunkName: "pages/pdf-editor/_documentId" */))
const _ef84e134 = () => interopDefault(import('../pages/queues/_id.vue' /* webpackChunkName: "pages/queues/_id" */))
const _4fec6738 = () => interopDefault(import('../pages/queues/_id/prioritize.vue' /* webpackChunkName: "pages/queues/_id/prioritize" */))
const _645b6e29 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _05aad7ae,
    name: "admin"
  }, {
    path: "/aging",
    component: _01faf253,
    name: "aging"
  }, {
    path: "/intake",
    component: _7e4852b0,
    name: "intake"
  }, {
    path: "/lead-import",
    component: _a1c3ffe0,
    name: "lead-import"
  }, {
    path: "/leads",
    component: _4f50e371,
    name: "leads"
  }, {
    path: "/retrieval",
    component: _4161264e,
    name: "retrieval"
  }, {
    path: "/admin/campaigns",
    component: _742177bd,
    name: "admin-campaigns"
  }, {
    path: "/admin/charges",
    component: _ecaca554,
    name: "admin-charges"
  }, {
    path: "/admin/devices",
    component: _1e68b218,
    name: "admin-devices"
  }, {
    path: "/admin/drugs",
    component: _3dc647aa,
    name: "admin-drugs"
  }, {
    path: "/admin/invoices",
    component: _13ee9742,
    name: "admin-invoices"
  }, {
    path: "/admin/key-phrases",
    component: _07c9ee83,
    name: "admin-key-phrases"
  }, {
    path: "/admin/lead-lists",
    component: _43b7dfc6,
    name: "admin-lead-lists"
  }, {
    path: "/admin/manufacturers",
    component: _3d89c9ce,
    name: "admin-manufacturers"
  }, {
    path: "/admin/tags",
    component: _71d10c92,
    name: "admin-tags"
  }, {
    path: "/admin/teams",
    component: _23de630d,
    name: "admin-teams"
  }, {
    path: "/admin/user-permissions",
    component: _228fe80a,
    name: "admin-user-permissions"
  }, {
    path: "/auth/callback",
    component: _3c4d0a23,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _58d9a97b,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _0f6c8e68,
    name: "auth-logout"
  }, {
    path: "/auth/reset-password",
    component: _e90e0692,
    name: "auth-reset-password"
  }, {
    path: "/intake/incoming-call",
    component: _8ba08c0a,
    name: "intake-incoming-call"
  }, {
    path: "/outreach/medical-release",
    component: _1636a19a,
    name: "outreach-medical-release"
  }, {
    path: "/retrieval/copy-services",
    component: _57281835,
    name: "retrieval-copy-services"
  }, {
    path: "/retrieval/document-requests",
    component: _57a9e296,
    name: "retrieval-document-requests"
  }, {
    path: "/retrieval/escalated",
    component: _04a04fee,
    name: "retrieval-escalated"
  }, {
    path: "/retrieval/exception",
    component: _6281956a,
    name: "retrieval-exception"
  }, {
    path: "/retrieval/lead-groups",
    component: _3d5d3fb8,
    name: "retrieval-lead-groups"
  }, {
    path: "/retrieval/medical-facilities",
    component: _7212b098,
    name: "retrieval-medical-facilities"
  }, {
    path: "/retrieval/upload",
    component: _60f6bb78,
    name: "retrieval-upload"
  }, {
    path: "/admin/campaigns/matter-types",
    component: _1c12fc5c,
    name: "admin-campaigns-matter-types"
  }, {
    path: "/admin/campaigns/medical-event-types",
    component: _478463f5,
    name: "admin-campaigns-medical-event-types"
  }, {
    path: "/admin/campaigns/question-sets",
    component: _4ad491f4,
    name: "admin-campaigns-question-sets"
  }, {
    path: "/retrieval/upload/3m-records",
    component: _2485c0d9,
    name: "retrieval-upload-3m-records"
  }, {
    path: "/admin/campaigns/question-sets/:id?",
    component: _6811c2ee,
    name: "admin-campaigns-question-sets-id"
  }, {
    path: "/admin/campaigns/:id",
    component: _ff977536,
    name: "admin-campaigns-id"
  }, {
    path: "/retrieval/copy-services/:copyServiceId?",
    component: _7899c02e,
    name: "retrieval-copy-services-copyServiceId"
  }, {
    path: "/retrieval/document-requests/:id?",
    component: _51273a5d,
    name: "retrieval-document-requests-id"
  }, {
    path: "/retrieval/lead-groups/:id?",
    component: _057dc8e8,
    name: "retrieval-lead-groups-id"
  }, {
    path: "/retrieval/medical-facilities/:id?",
    component: _9099f1c8,
    name: "retrieval-medical-facilities-id"
  }, {
    path: "/documents/:documentId?",
    component: _3a27f1b5,
    children: [{
      path: "",
      component: _0d78c710,
      name: "documents-documentId"
    }, {
      path: "tasks/:taskId?",
      component: _64968e78,
      name: "documents-documentId-tasks-taskId"
    }]
  }, {
    path: "/leads/:leadId",
    component: _04dc4195,
    children: [{
      path: "",
      component: _42b48458,
      name: "leads-leadId"
    }, {
      path: "activity",
      component: _457c950e,
      name: "leads-leadId-activity"
    }, {
      path: "blockers",
      component: _d82089ba,
      name: "leads-leadId-blockers"
    }, {
      path: "documents",
      component: _6ae4a43e,
      name: "leads-leadId-documents"
    }, {
      path: "import-data",
      component: _03a4cdd8,
      name: "leads-leadId-import-data"
    }, {
      path: "lift-signature",
      component: _3d0baf3a,
      name: "leads-leadId-lift-signature"
    }, {
      path: "outreach-requests",
      component: _62428c35,
      name: "leads-leadId-outreach-requests"
    }, {
      path: "signature-requests",
      component: _6802b823,
      name: "leads-leadId-signature-requests"
    }, {
      path: "smart-advocate-notes",
      component: _35f8cbcd,
      name: "leads-leadId-smart-advocate-notes"
    }, {
      path: "tasks",
      component: _08bc8097,
      name: "leads-leadId-tasks"
    }, {
      path: "tasks/outreach-requests",
      component: _ac9cd298,
      name: "leads-leadId-tasks-outreach-requests"
    }, {
      path: "tasks/outreach-requests/:outreachRequestId?",
      component: _599c07b4,
      name: "leads-leadId-tasks-outreach-requests-outreachRequestId"
    }, {
      path: "lift-signature/:documentId?",
      component: _1ac166f4,
      name: "leads-leadId-lift-signature-documentId"
    }, {
      path: "outreach-requests/:outreachRequestId?",
      component: _1dc3cbd3,
      name: "leads-leadId-outreach-requests-outreachRequestId"
    }, {
      path: "tasks/:taskId",
      component: _232fc0a4,
      name: "leads-leadId-tasks-taskId"
    }]
  }, {
    path: "/pdf-editor/:documentId?",
    component: _09f1e207,
    name: "pdf-editor-documentId"
  }, {
    path: "/queues/:id?",
    component: _ef84e134,
    name: "queues-id",
    children: [{
      path: "prioritize",
      component: _4fec6738,
      name: "queues-id-prioritize"
    }]
  }, {
    path: "/",
    component: _645b6e29,
    name: "index"
  }, {
    path: "/queues/:id/leads/:leadId",
    name: "queues-id-leads-leadId"
  }, {
    path: "/queues/:id/leads/:leadId/tasks",
    name: "queues-id-leads-leadId-tasks"
  }, {
    path: "/queues/:id/documents/:documentId",
    name: "queues-id-documents-documentId"
  }, {
    path: "/queues/:id/documents/:documentId/tasks",
    name: "queues-id-documents-documentId-tasks"
  }, {
    path: "/queues/:id/documents/:documentId/tasks/:taskId",
    name: "queues-id-documents-documentId-tasks-taskId"
  }, {
    path: "/queues/:id/leads/:leadId/tasks/:taskId",
    name: "queues-id-leads-leadId-tasks-taskId"
  }, {
    path: "/queues/:id/leads/:leadId/activity",
    name: "queues-id-leads-leadId-activity"
  }, {
    path: "/queues/:id/leads/:leadId/documents",
    name: "queues-id-leads-leadId-documents"
  }, {
    path: "/queues/:id/leads/:leadId/outreach-requests",
    name: "queues-id-leads-leadId-outreach-requests"
  }, {
    path: "/queues/:id/leads/:leadId/outreach-requests/:outreachRequestId",
    name: "queues-id-leads-leadId-outreach-requests-outreachRequestId"
  }, {
    path: "/leads/:id",
    name: "leads-id"
  }, {
    path: "/leads/:id/claimant-information",
    name: "leads-id-claimant-information"
  }, {
    path: "/leads/:id/case-coordination-closeout",
    name: "leads-id-case-coordination-closeout"
  }, {
    path: "/leads/:id/census-plus",
    name: "leads-id-census-plus"
  }, {
    path: "/leads/:id/document-requests",
    name: "leads-id-document-requests"
  }, {
    path: "/leads/:id/intake-qc",
    name: "leads-id-intake-qc"
  }, {
    path: "/leads/:id/law-firm-approval",
    name: "leads-id-law-firm-approval"
  }, {
    path: "/leads/:id/medical-events",
    name: "leads-id-medical-events"
  }, {
    path: "/leads/:id/medical-release",
    name: "leads-id-medical-release"
  }, {
    path: "/leads/:id/pacer-check",
    name: "leads-id-pacer-check"
  }, {
    path: "/leads/:id/questionnaire",
    name: "leads-id-questionnaire"
  }, {
    path: "/leads/:id/retainer",
    name: "leads-id-retainer"
  }, {
    path: "/leads/:id/mail-medical-release",
    name: "leads-id-mail-medical-release"
  }, {
    path: "/leads/:id/declinations/new",
    name: "leads-id-declinations-new"
  }, {
    path: "/leads/:id/declinations",
    name: "leads-id-declinations"
  }, {
    path: "/leads/:id/notarizations/new",
    name: "leads-id-notarizations-new"
  }, {
    path: "/leads/:id/notarizations",
    name: "leads-id-notarizations"
  }, {
    path: "/leads/:id/task-summary",
    name: "leads-id-task-summary"
  }],

  parseQuery: function(q) {
            return require('qs').parse(q);
        },
  stringifyQuery: function(q) {
            const r = require('qs').stringify(q);
            return r ? '?' + r : '';
        },
  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
