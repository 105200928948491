import { render, staticRenderFns } from "./NavUserTaskBlockers.vue?vue&type=template&id=98b17060&"
import script from "./NavUserTaskBlockers.vue?vue&type=script&lang=js&"
export * from "./NavUserTaskBlockers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserBlockers: require('/home/node/components/blockers/UserBlockers.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VIcon,VMenu,VSnackbar})
