//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    blockers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    leadDetails(leadId) {
      const leads = this.blockers.map(blocker => blocker.lead)
      if (!leads || leads.length === 0) return
      const lead = leads.find(l => l?.id === leadId)
      if (!lead) return
      return `${lead.lawFirm.name} - ${lead.matterType} - ${lead.claimant.firstName} ${lead.claimant.lastName}`
    },
  },
}
