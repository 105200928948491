//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { padStart } from 'lodash'
import startCase from 'lodash/startCase'
import { required, requiredIf, helpers } from 'vuelidate/lib/validators'

export default {
  props: {
    leadId: {
      type: String,
      required: false,
      default: null,
    },
    form: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    splittable: {
      type: Boolean,
      required: false,
      default: false,
    },
    generatedFileName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data: () => ({
    copiedCardNumber: false,
    copiedCardCvv: false,
    copiedCardAddress: false,
    creditCard: null,
  }),

  validations: {
    form: {
      startPage: {
        required: requiredIf(function() {
          return this.splittable || this.form.pageSubSet
        }),
        minValue: value => {
          return !helpers.req(value) || value > 0
        },
      },
      endPage: {
        required: requiredIf(function() {
          return this.splittable || this.form.pageSubSet
        }),
        minValue: value => {
          return !helpers.req(value) || value > 0
        },
      },
      invoiceId: {
        required,
      },
      payeeName: {
        required,
      },
      payeeAddress1: {
        required,
      },
      payeeCity: {
        required,
      },
      payeeState: {
        required,
      },
      payeeZip: {
        required,
      },
      invoiceAmount: {
        required,
        minValue: value => value > 0,
      },
      invoicePaymentMethod: { required },
      invoiceDate: { required },
    },
  },

  computed: {
    creditCardDate() {
      if (!this.creditCard) return '00/00'
      return `${padStart(this.creditCard.exp_month, 2, '0')}/${padStart(
        String(this.creditCard.exp_year).substring(2, 4),
        2,
        '0',
      )}`
    },

    creditCardNumber() {
      if (!this.creditCard) return '0000 0000 0000 0000'
      return this.creditCard.number.replace(/(.{4})/g, '$1 ')
    },

    creditCardAddress() {
      if (!this.creditCard) return ''
      return this.creditCard.cardholder.billing.address.line2
        ? `${this.creditCard.cardholder.billing.address.line1} ${this.creditCard.cardholder.billing.address.line2}, ${this.creditCard.cardholder.billing.address.city}, ${this.creditCard.cardholder.billing.address.state} ${this.creditCard.cardholder.billing.address.postal_code}`
        : `${this.creditCard.cardholder.billing.address.line1}, ${this.creditCard.cardholder.billing.address.city}, ${this.creditCard.cardholder.billing.address.state} ${this.creditCard.cardholder.billing.address.postal_code}`
    },

    validPageRange() {
      if (this.form.endPage && this.form.startPage) return this.form.startPage > this.form.endPage
      return true
    },

    startPageErrors() {
      const errors = []
      if (!this.$v.form.startPage.$dirty && !this.$v.$dirty) return errors
      if (!this.$v.form.startPage.required) errors.push('Start Page is required')
      if (!this.$v.form.startPage.minValue) errors.push('Start Page must be 1 or greater')
      return errors
    },
    endPageErrors() {
      const errors = []
      if (!this.$v.form.endPage.$dirty && !this.$v.$dirty) return errors
      if (!this.$v.form.endPage.required) errors.push('End Page is required')
      if (!this.$v.form.endPage.minValue) errors.push('End Page must be 1 or greater')
      return errors
    },
  },

  watch: {
    async leadId(val, prev) {
      if (val === prev) return
      await this.getCreditCard()
    },
    async 'form.invoicePaymentMethod'(val, prev) {
      if (val === prev) return
      await this.getCreditCard()
    },
    'form.documentRequest.medicalFacility'(val, prev) {
      if (!val) return
      if (val?.id === prev?.id) return

      this.preFillPayeeFields()
    },
  },

  async mounted() {
    this.preFillPayeeFields()
    await this.getCreditCard()
  },

  methods: {
    preFillPayeeFields() {
      const facility = this.form?.documentRequest?.medicalFacility
      if (!facility) return

      this.$refs.payeeNameRef.$emit(
        'input',
        facility.billingName ?? facility?.copyService?.billingName,
      )
      this.$refs.payeeAddress1Ref.$emit(
        'input',
        facility.billingAddress1 ?? facility?.copyService?.billingAddress1,
      )
      this.$refs.payeeAddress2Ref.$emit(
        'input',
        facility.billingAddress2 ?? facility?.copyService?.billingAddress2,
      )
      this.$refs.payeeCityRef.$emit(
        'input',
        facility.billingCity ?? facility?.copyService?.billingCity,
      )
      this.$refs.payeeStateRef.$emit(
        'input',
        facility.billingState ?? facility?.copyService?.billingState,
      )
      this.$refs.payeeZipRef.$emit(
        'input',
        facility.billingZipCode ?? facility?.copyService?.billingZipCode,
      )
      this.$refs.invoicePaymentMethodRef.$emit(
        'input',
        facility.preferredPaymentMethod ?? facility?.copyService?.preferredPaymentMethod,
      )

      this.$v.form.$touch()
    },
    requiredPropertyError(property, label = '') {
      if (!label)
        label = startCase(property)
          .replace(/([A-Z][a-z])/g, ' $1')
          .replace(/(\d)/g, ' $1')
      const errors = []
      if (!this.$v.form[property]?.$dirty) return errors
      if (!this.$v.form[property]?.required) errors.push(`${label} is required`)
      return errors
    },

    copyCardNumber() {
      navigator.clipboard.writeText(this.creditCard.number)
      this.copiedCardNumber = true
      setTimeout(() => {
        this.copiedCardNumber = false
      }, 2000)
    },

    copyCardCvv() {
      navigator.clipboard.writeText(this.creditCard.cvc)
      this.copiedCardCvv = true
      setTimeout(() => {
        this.copiedCardCvv = false
      }, 2000)
    },

    copyCardAddress() {
      navigator.clipboard.writeText(
        this.creditCard.cardholder.billing.address.line2
          ? `${this.creditCard.cardholder.billing.address.line1} ${this.creditCard.cardholder.billing.address.line2} ${this.creditCard.cardholder.billing.address.city}, ${this.creditCard.cardholder.billing.address.state} ${this.creditCard.cardholder.billing.address.postal_code}`
          : `${this.creditCard.cardholder.billing.address.line1} ${this.creditCard.cardholder.billing.address.city}, ${this.creditCard.cardholder.billing.address.state} ${this.creditCard.cardholder.billing.address.postal_code}`,
      )
      this.copiedCardAddress = true
      setTimeout(() => {
        this.copiedCardAddress = false
      }, 2000)
    },

    async getCreditCard() {
      if (!this.leadId || this.form.invoicePaymentMethod !== 'Credit Card') {
        this.creditCard = null
        return
      }

      this.creditCard = await this.$axios.$post(`v1/credit-cards`, {
        leadId: this.leadId,
      })
    },
  },
}
